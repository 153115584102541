import axios from "axios";
import { useEffect, useState } from "react";
import { Model, StartUpApiResponse } from "../types";
import { handleNetworkErrors } from "../utils/handleNetworkErrors";

export const useModel = (
	setThrowsError: React.Dispatch<React.SetStateAction<undefined>>
): Model | undefined => {
	const [model, setModel] = useState<Model | undefined>();

	useEffect(() => {
		const getModel = async () => {
			try {
				const { data }: { data: StartUpApiResponse } = await axios.get(
					"https://ey6zu8r7jk.execute-api.eu-west-2.amazonaws.com/prod/getrefactor"
				);

				if (!data.response) {
					const errMsg = data.message
						? data.message
						: "Something went wrong";
					throw Error(errMsg);
				}

				const { Description } = data.response.Items.filter(
					(item) => item.Name === "model"
				)[0];
				if (Description !== "tax" && Description !== "tier")
					setModel("error");
				const currModel = Description as Model;
				setModel(currModel);
			} catch (err: unknown) {
				handleNetworkErrors(err);
			}
		};
		if (process.env.REACT_APP_MODEL) {
			const model = process.env.REACT_APP_MODEL;
			if (model === "tax" || model === "tier") setModel(model);
		} else {
			getModel().catch((err: Error) => {
				console.log(err);
				setThrowsError(() => {
					throw err;
				});
			});
		}
	}, [setThrowsError]);

	return model;
};
