import React from "react";
import { Link } from "react-router-dom";

interface AccessDeniedProps {
	page?: string;
}

const AccessDenied: React.FC<AccessDeniedProps> = ({ page }): JSX.Element => {
	return page === "admin" ? (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				height: "calc(100vh - 137px)",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					fontSize: "18px",
					textAlign: "center",
				}}
			>
				<h1>Access Denied</h1>
				<h3>You must log in as Admin to access this page</h3>
				<h3>
					Click{" "}
					<Link
						to="/"
						style={{ textDecoration: "none", color: "red" }}
					>
						here
					</Link>{" "}
					to login
				</h3>
			</div>
		</div>
	) : (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				height: "calc(100vh - 137px)",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					fontSize: "18px",
					textAlign: "center",
				}}
			>
				<h1>Access Denied</h1>
				<h3>You must log in to access this page</h3>
				<h3>
					Click{" "}
					<Link
						to="/"
						style={{ textDecoration: "none", color: "red" }}
					>
						here
					</Link>{" "}
					to login
				</h3>
			</div>
		</div>
	);
};

export default AccessDenied;
