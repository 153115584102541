import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useThresholds } from "../../hooks";
import { getThresholds } from "../../utils";
import { getMaxThresholds } from "../../utils/getMaxThreshold";
import { Threshold } from "../../types";
import BreakdownRow from "../BreakdownRow/BreakdownRow";

interface RangeModalProps {
	open: boolean;
	handleClose: () => void;
}

const RangeModal: React.FC<RangeModalProps> = ({ open, handleClose }) => {
	const [allThresholds, setAllThresholds] = useState<Array<Threshold>>([]);
	const [, /* throwsError */ setThrowsError] = useState();
	const thresholds = useThresholds(setThrowsError);

	useEffect(() => {
		if (thresholds) {
			const maxThreshold = getMaxThresholds({ thresholds });
			setAllThresholds(
				getThresholds({ data: maxThreshold + 1, thresholds })
			);
		}
	}, [thresholds]);

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle
					id="alert-dialog-title"
					style={{
						textAlign: "center",
						backgroundColor: "#962127",
						color: "#ffffff",
					}}
				>
					{"Discount Tiers"}
				</DialogTitle>
				<DialogContent
					style={{
						backgroundColor: "#ffe5dd",
						paddingTop: "1.5em",
						paddingBottom: "1.5em",
					}}
				>
					<DialogContentText id="alert-dialog-description">
						<tr
							style={{
								verticalAlign: "middle",
							}}
						>
							<th style={{ width: 200 }}>Range (TB)</th>
							<th style={{ width: 200 }}>
								Standard Retention Charge (£/GB/Month)
							</th>
							<th style={{ width: 200 }}>
								{" "}
								Extended Retention Additional Charge
								(£/GB/Month)
							</th>
						</tr>
						{allThresholds.map((option, idx) => {
							return (
								<BreakdownRow
									key={idx}
									allThresholds={allThresholds}
									threshold={option}
									idx={idx}
								/>
							);
						})}
					</DialogContentText>
				</DialogContent>
				<DialogActions
					style={{
						display: "flex",
						justifyContent: "center",
						backgroundColor: "#ffe5dd",
					}}
				>
					<Button onClick={handleClose} color="primary">
						<strong>OK</strong>
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default RangeModal;
