import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Option } from "../../types";
import { formatValue2Dp, sortOptionsByCost } from "../../utils";
import SelectionOption from "../SelectionOption/SelectionOption";

import Margin from "../Margin/Margin";
import {
	CategoryGrid,
	CategoryNameDiv,
	CategoryTitle,
	Cost,
	CostDiv,
} from "./styles";
import { toGBP } from "../../globals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

interface CategoryProps {
	options: Option[];
	category: string;
	setTotal: React.Dispatch<React.SetStateAction<number>>;
	setTotalExMargin: React.Dispatch<React.SetStateAction<number>>;
}

const Category: React.FC<CategoryProps> = ({
	options,
	category,
	setTotal,
	setTotalExMargin,
}: CategoryProps): JSX.Element => {
	const [categoryCost, setCategoryCost] = useState<number>(0);
	const [categoryMargin, setCategoryMargin] = useState<number>(0); // As a % - /100 for usage.

	const prevCostRef = useRef(0) as MutableRefObject<number>;
	const prevCostExMarginRef = useRef(0) as MutableRefObject<number>;

	// Gets saved margin from sessionStorage
	useEffect(() => {
		const savedMargin = sessionStorage.getItem(`${category}-margin`);
		if (savedMargin) {
			const savedMarginToInt = parseFloat(savedMargin);
			setCategoryMargin(savedMarginToInt);
		}
	}, [category]);

	// Updates total cost and previous category cost
	// async await needed here for updating totals despite saying no effect... hmmm....
	useEffect(() => {
		const updateTotals = async () => {
			const decimalMargin = categoryMargin / 100;
			const newCost = categoryCost + categoryCost * decimalMargin;
			await setTotal((total) =>
				formatValue2Dp(total - prevCostRef.current + newCost)
			);
			await setTotalExMargin((total) =>
				formatValue2Dp(
					total + categoryCost - prevCostExMarginRef.current
				)
			);
			prevCostRef.current = formatValue2Dp(newCost);
			prevCostExMarginRef.current = formatValue2Dp(categoryCost);
			sessionStorage.setItem(
				`${category}-totalCost`,
				formatValue2Dp(newCost).toString()
			);
		};
		updateTotals();
	}, [
		categoryCost,
		setTotal,
		prevCostRef,
		prevCostExMarginRef,
		categoryMargin,
		setTotalExMargin,
		category,
	]);

	const renderOptions = () => {
		const sortedOptions = sortOptionsByCost(options);
		return sortedOptions.map((option) => (
			<SelectionOption
				setCategoryCost={setCategoryCost}
				{...option}
				category={category}
				key={option.Name}
			/>
		));
	};

	const handleMarginChange = (
		e: React.ChangeEvent<HTMLInputElement>
	): void => {
		const newMargin =
			e.target.value === ""
				? 0
				: formatValue2Dp(parseFloat(e.target.value));
		setCategoryMargin(newMargin);
		sessionStorage.setItem(`${category}-margin`, newMargin.toString());
	};

	return (
		<CategoryGrid>
			<CategoryNameDiv>
				<CategoryTitle>{category}</CategoryTitle>
				{category === "Project Management Cost" && (
					<>
						<FontAwesomeIcon
							style={{ marginTop: "0.5em" }}
							data-tip
							data-for="projectmanagement"
							icon={faQuestionCircle}
						/>
						<ReactTooltip
							id="projectmanagement"
							effect="solid"
							place="right"
						>
							A Project Manager is generally required to
							co-ordinate the various design and implementation
							activities. Three days have been factored in to
							address this requirement.
						</ReactTooltip>
					</>
				)}
			</CategoryNameDiv>
			<div
				style={{
					gridColumn: "2/3",
					display: "grid",
					gridTemplateColumns: "5%  42% 30% 17%",
					width: "100%",
				}}
			>
				{renderOptions()}
			</div>
			<Margin
				category={category}
				categoryMargin={categoryMargin}
				handleMarginChange={handleMarginChange}
			/>
			<CostDiv>
				<Cost data-test-id={`${category}-cost`}>
					{toGBP.format(
						formatValue2Dp(
							categoryCost + categoryCost * (categoryMargin / 100)
						)
					)}
				</Cost>
			</CostDiv>
		</CategoryGrid>
	);
};

export default Category;
