import styled from "@emotion/styled";

export const RemoveTierContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	width: 100%;
	height: 100%;
`;

export const RemoveTierAlignment = styled.div`
	display: flex;
	justify-content: center;
`;

export const RemoveTierContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 15%;
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;
