import React, { useState, useEffect, useCallback } from "react";
import { Card } from "primereact/card";
import { Link } from "react-router-dom";
import AddTier from "../AddTier/AddTier";
import EditTier from "../EditTier/EditTier";
import RemoveTier from "../RemoveTier/RemoveTier";
import ModelSwitch from "../ModelSwitch/ModelSwitch";
import { Button } from "primereact/button";
import EditStandard from "../EditStandard/EditStandard";
import EditCostOptions from "../EditCostOptions/EditCostOptions";
import EditMonthly from "../EditMonthlyCost/EditMonthlyCost";
import { Auth, API } from "aws-amplify";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const AdminOptions: React.FC = (): JSX.Element => {
	const [addTier, setAddTier] = useState<boolean>(false);
	const [editTier, setEditTier] = useState<boolean>(false);
	const [removeTier, setRemoveTier] = useState<boolean>(false);
	const [editCost, setEditCost] = useState<boolean>(false);
	const [editStartUp, setEditStartUp] = useState<boolean>(false);
	const [editMonthly, setEditMonthly] = useState<boolean>(false);
	const [userCount, setUserCount] = useState<any>();

	const listUsers = useCallback(async () => {
		let nextToken: any;
		const apiName = "AdminQueries";
		const path = "/listUsersInGroup";
		const myInit = {
			queryStringParameters: {
				groupname: "User",
				token: nextToken,
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: `${(await Auth.currentSession())
					.getAccessToken()
					.getJwtToken()}`,
			},
		};
		const response = await API.get(apiName, path, myInit);
		console.log(response);
		return response;
	}, []);

	useEffect(() => {
		listUsers()
			.then((response) => {
				setUserCount(response.Users.length);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [listUsers]);

	function handleAddTier() {
		setAddTier(true);
		setEditTier(false);
		setRemoveTier(false);
		setEditCost(false);
		setEditStartUp(false);
		setEditMonthly(false);
	}
	function handleEditTier() {
		setAddTier(false);
		setEditTier(true);
		setRemoveTier(false);
		setEditCost(false);
		setEditStartUp(false);
		setEditMonthly(false);
	}
	function handleRemoveTier() {
		setAddTier(false);
		setEditTier(false);
		setRemoveTier(true);
		setEditCost(false);
		setEditStartUp(false);
		setEditMonthly(false);
	}
	function handleEditCost() {
		setAddTier(false);
		setEditTier(false);
		setRemoveTier(false);
		setEditCost(true);
		setEditStartUp(false);
		setEditMonthly(false);
	}
	function handleEditStartUp() {
		setAddTier(false);
		setEditTier(false);
		setRemoveTier(false);
		setEditCost(false);
		setEditStartUp(true);
		setEditMonthly(false);
	}

	function handleEditMonthly() {
		setAddTier(false);
		setEditTier(false);
		setRemoveTier(false);
		setEditCost(false);
		setEditStartUp(false);
		setEditMonthly(true);
	}

	function clearOptions() {
		setAddTier(false);
		setEditTier(false);
		setRemoveTier(false);
		setEditCost(false);
		setEditStartUp(false);
		setEditMonthly(false);
		window.history.go(-1);
	}

	function clearOptionsCost() {
		setAddTier(false);
		setEditTier(false);
		setRemoveTier(false);
		setEditCost(true);
		setEditStartUp(false);
		setEditMonthly(false);
		window.history.go(-1);
	}

	return !addTier &&
		!editTier &&
		!removeTier &&
		!editCost &&
		!editStartUp &&
		!editMonthly &&
		userCount !== undefined ? (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignContent: "center",
				width: "100%",
				height: "100%",
			}}
		>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					textAlign: "center",
				}}
			>
				<h4>{`Unique User Count: ${userCount}`}</h4>
			</div>{" "}
			<ModelSwitch />
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					textAlign: "center",
				}}
			>
				<div>
					<Link
						to="#"
						style={{ textDecoration: "none" }}
						onClick={handleEditCost}
						id="edit-cost-card"
					>
						<Card
							style={{
								backgroundColor: "#A30B1A",
								margin: 40,
								width: 250,
								height: 150,
								color: "white",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
							}}
						>
							Edit Costs
						</Card>
					</Link>
				</div>
				<div>
					<Link
						to="#"
						style={{ textDecoration: "none" }}
						onClick={handleAddTier}
						id="add-tier-card"
					>
						<Card
							style={{
								backgroundColor: "#A30B1A",
								margin: 40,
								width: 250,
								height: 150,
								color: "white",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
							}}
						>
							Add Discount Tier
						</Card>
					</Link>
				</div>
				<div>
					<Link
						to="#"
						style={{ textDecoration: "none" }}
						onClick={handleEditTier}
						id="edit-tier-card"
					>
						<Card
							style={{
								backgroundColor: "#A30B1A",
								margin: 40,
								width: 250,
								height: 150,
								color: "white",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
							}}
						>
							Edit Discount Tier
						</Card>
					</Link>
				</div>
				<div>
					<Link
						to="#"
						style={{ textDecoration: "none" }}
						onClick={handleRemoveTier}
						id="remove-tier-card"
					>
						<Card
							style={{
								backgroundColor: "#A30B1A",
								margin: 40,
								width: 250,
								height: 150,
								color: "white",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
							}}
						>
							Remove Discount Tier
						</Card>
					</Link>
				</div>
			</div>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<Button
					label="Back"
					className="p-button-raised"
					style={{
						marginTop: 50,
						width: 100,
						fontFamily: "FujitsuSans",
					}}
					onClick={() => {
						if (window.history.length === 1) {
							window.location.href = "/";
						} else {
							window.history.go(-1);
							return false;
						}
					}}
				></Button>
			</div>
		</div>
	) : addTier ? (
		<AddTier clearOptions={clearOptions} />
	) : editTier ? (
		<EditTier clearOptions={clearOptions} />
	) : removeTier ? (
		<RemoveTier clearOptions={clearOptions} />
	) : editStartUp ? (
		<EditStandard clearOptions={clearOptionsCost} />
	) : editCost ? (
		<EditCostOptions
			handleEditMonthly={handleEditMonthly}
			handleEditStartUp={handleEditStartUp}
			clearOptions={clearOptions}
		/>
	) : editMonthly ? (
		<EditMonthly clearOptions={clearOptionsCost} />
	) : (
		<LoadingSpinner />
	);
};

export default AdminOptions;
