import styled from "@emotion/styled";

export const AddTierContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	width: 100%;
	height: 100%;
`;

export const AddTierAlignment = styled.div`
	display: flex;
	justify-content: center;
`;

export const AddTierContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 15%;
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const Input = styled.input`
	text-align: center;
	padding: 0.3em;
	margin: 0.3em;
	background-color: #fefefe;
	color: #000000;
	font-size: 1em;
	border-width: 0.1em;
	margin-bottom: 35px;
	height: 3.5vh;
	width: 15vw;
`;
