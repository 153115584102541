import { useEffect, useState } from "react";
import axios from "axios";
import { Threshold } from "../types";
import { useThresholds } from "./useThresholds";

export const useSortDbTier = (): void => {
	const [discounts, setDiscounts] = useState<Array<Threshold>>([]);
	const [, /* throwsError */ setThrowsError] = useState();

	const threshold = useThresholds(setThrowsError);

	useEffect(() => {
		if (threshold) {
			setDiscounts(threshold);
		}
	}, [threshold]);

	async function sortData() {
		const filter = discounts.filter((option: any) => {
			return option.tier > discounts.length;
		});
		const ascending = discounts.sort((a, b) =>
			a.threshold > b.threshold ? 1 : -1
		);
		const body = [];
		for (let i = 0; i < ascending.length; i++) {
			body[i] = {
				tier: (i + 1).toString(),
				threshold: ascending[i].threshold.toString(),
				basePrice: ascending[i].basePrice.toString(),
				extendedAdditional: ascending[i].extendedAdditional.toString(),
			};
		}

		if (filter[0]) {
			for (let i = 0; i < discounts.length; i++) {
				await axios
					.delete(
						`https://cors-anywhere.herokuapp.com/https://ey6zu8r7jk.execute-api.eu-west-2.amazonaws.com/prod/getdiscounts?tier=${discounts[i].tier}`
					)
					.then(() => console.log("deleted"))
					.catch((err) => {
						console.log(err);
					});
			}

			for (let i = 0; i < ascending.length; i++) {
				try {
					await fetch(
						"https://ey6zu8r7jk.execute-api.eu-west-2.amazonaws.com/prod/addmultiple",
						{
							body: JSON.stringify(body),
							method: "POST",
						}
					);
				} catch (error) {
					console.log(error);
				}
				return console.log("data sorted");
			}
		}
	}
	sortData();
};
