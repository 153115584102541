import { Threshold } from "../types";

// Filters all thresholds for the ones the total GB exceeds
export const getThresholds = ({
	data,
	thresholds,
}: {
	data: number;
	thresholds: Threshold[];
}): Threshold[] => {
	const validThresholds = thresholds.filter(
		(threshold) => threshold.threshold <= data
	);

	const sortedThresholds = validThresholds.sort(
		(a, b) => a.threshold - b.threshold
	);
	return sortedThresholds;
};
