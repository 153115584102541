import React from "react";
import { Action } from "../../types";
import { formatValue3Dp, regexMatchDecimal } from "../../utils";
import { InputDiv, InputLabel, NumberInput } from "../MonthlyCostInput/styles";

interface MonthlyCostInputMarginProps {
	handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
	formatInput: ({
		e,
		decimalPlaces,
	}: {
		e: React.ChangeEvent<HTMLInputElement>;
		decimalPlaces: number;
	}) => void;
	dispatch: React.Dispatch<Action>;
}

const MonthlyCostInputMargin = React.forwardRef<
	HTMLInputElement,
	MonthlyCostInputMarginProps
>(
	(props, ref): JSX.Element => {
		return (
			<InputDiv>
				<InputLabel htmlFor="margin">
					Total Monthly Gross Margin (%)
				</InputLabel>
				<NumberInput
					id="margin"
					step={0.001}
					ref={ref}
					type="number"
					name="margin"
					defaultValue={0}
					min={0}
					onFocus={(e) => e.target.select()}
					onBlur={(e) => props.handleBlur(e)}
					onChange={(e) => {
						if (regexMatchDecimal(e.target.value)) return; // Note - regex match 0.0 or 0.00
						props.formatInput({ e, decimalPlaces: 3 });

						// Treat blank space as 0
						if (e.target.value === "") {
							props.dispatch({
								type: "margin",
								payload: 0,
							});
						} else {
							props.dispatch({
								type: "margin",
								payload: formatValue3Dp(
									parseFloat(e.target.value)
								),
							});
						}
					}}
				/>
			</InputDiv>
		);
	}
);

export default MonthlyCostInputMargin;
