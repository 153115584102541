import React, { useState, useEffect } from "react";
import { getDiscounts } from "../../serviceWorker";
import { Button } from "primereact/button";
import ConfirmationPopUp from "../ConfirmationPopUp/ConfirmationPopUp";
import { sortDB } from "../../utils/sortDb";
import { checkThreshold } from "../../utils/checkThreshold";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {
	EditTierContainer,
	EditTierAlignment,
	EditTierContent,
	ButtonContainer,
	Input,
} from "./styles";
import { Threshold } from "../../types";
interface discountOptions {
	tier: number;
	extendedAdditional: number;
	basePrice: number;
	threshold: number;
}

interface EditTierProps {
	clearOptions: () => void;
}

const EditTier: React.FC<EditTierProps> = ({ clearOptions }): JSX.Element => {
	const [discounts, setdiscounts] = useState<Array<Threshold>>([]);
	const [selectedTier, setselectedTier] = useState<Array<discountOptions>>(
		[]
	);
	const [newThreshold, setnewThreshold] = useState<number>();
	const [newBasePrice, setnewBasePrice] = useState<number>();
	const [
		newExtendedAdditional,
		setnewExtendedAdditional,
	] = useState<number>();
	const [sorted, setSorted] = useState<Array<discountOptions>>([]);
	const [error, setError] = useState<boolean>(false);
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

	useEffect(() => {
		sortDB().then((response) => {
			setLoading(response);
		});
		getDiscounts().then((response) => {
			setdiscounts(response);
		});
	}, []);

	useEffect(() => {
		getDiscounts().then((response) => {
			setdiscounts(response);
		});
	}, [loading]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		getDiscounts().then((response) => {
			setdiscounts(response);
		});
		setselectedTier([]);
		setnewThreshold(undefined);
		setnewBasePrice(undefined);
		setnewExtendedAdditional(undefined);

		clearInputs();
	};

	useEffect(() => {
		if (
			newBasePrice &&
			newThreshold !== undefined &&
			newExtendedAdditional &&
			!error
		) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [newBasePrice, newThreshold, newExtendedAdditional, error]);

	useEffect(() => {
		if (discounts[0]) {
			setSorted(
				discounts.sort((a: discountOptions, b: discountOptions) => {
					return a.threshold > b.threshold ? 1 : -1;
				})
			);
		}
	}, [discounts]);

	useEffect(() => {
		if (selectedTier[0]) {
			setnewThreshold(selectedTier[0].threshold);
			setnewBasePrice(selectedTier[0].basePrice);
			setnewExtendedAdditional(selectedTier[0].extendedAdditional);
			let threshold = document.getElementById(
				"new-threshold"
			) as HTMLInputElement;
			if (selectedTier[0].tier === 1) {
				threshold.disabled = true;
			} else {
				threshold.disabled = false;
			}
		}
	}, [selectedTier]);

	function clearInputs() {
		const dropdown = document.getElementById(
			"dropdown"
		) as HTMLSelectElement;
		const threshold = document.getElementById(
			"new-threshold"
		) as HTMLInputElement;
		const basePrice = document.getElementById(
			"new-base-price"
		) as HTMLInputElement;
		const extendedAdditional = document.getElementById(
			"new-extended-price"
		) as HTMLInputElement;
		threshold.value = "";
		basePrice.value = "";
		extendedAdditional.value = "";

		dropdown.selectedIndex = 0;
	}

	async function editTier() {
		handleClickOpen();
		try {
			await fetch(
				"https://ey6zu8r7jk.execute-api.eu-west-2.amazonaws.com/prod/getdiscounts",
				{
					body: JSON.stringify({
						tier: selectedTier[0].tier.toString(),
						threshold: newThreshold?.toString(),
						basePrice: newBasePrice?.toString(),
						extendedAdditional: newExtendedAdditional?.toString(),
					}),
					method: "POST",
				}
			);
		} catch (error) {
			console.log(error);
		}
		await sortDB();
		setLoadingRequest(false);
	}

	return !loading ? (
		<EditTierContainer>
			<EditTierAlignment>
				<EditTierContent>
					<ConfirmationPopUp
						open={open}
						handleClose={handleClose}
						handleClickOpen={handleClickOpen}
						type="Edited"
						loading={loadingRequest}
					/>

					<h1>Edit Tier</h1>
					<select
						id="dropdown"
						style={{ marginBottom: 20 }}
						onChange={(e: any) => {
							const index = e.nativeEvent.target.selectedIndex;
							const label = e.nativeEvent.target[index].text;
							const tier = parseInt(
								label.substr(label.length - 1)
							);

							setselectedTier(
								discounts.filter((option) => {
									return option.tier === tier;
								})
							);
						}}
					>
						<option></option>
						{sorted
							? sorted.map((option: discountOptions) => {
									return (
										<option
											key={option.tier}
										>{`Tier ${option.tier}`}</option>
									);
							  })
							: null}
					</select>

					<h4>Threshold (GB)</h4>
					<Input
						id="new-threshold"
						type="number"
						style={{ marginBottom: error ? 0 : undefined }}
						step={1}
						min={0}
						value={newThreshold}
						onChange={(e) => {
							var value = parseInt(e.target.value);
							setnewThreshold(value);
							if (selectedTier[0]) {
								if (
									parseInt(e.target.value) !==
									selectedTier[0].threshold
								) {
									if (
										checkThreshold(value, discounts) ===
										true
									) {
										setError(true);
										setButtonDisabled(true);
									} else if (
										checkThreshold(value, discounts) ===
										false
									) {
										setError(false);
									}
								}
							}
						}}
						defaultValue={
							selectedTier[0] !== undefined
								? selectedTier[0].threshold
								: undefined || ""
						}
					></Input>
					{error ? (
						<p
							id="error-message"
							style={{ color: "red", fontSize: 12 }}
						>
							This threshold already exists
						</p>
					) : (
						<div></div>
					)}

					<h4>Standard Retention Charge (£/GB/month)</h4>
					<Input
						id="new-base-price"
						type="number"
						style={{ marginBottom: 20 }}
						step={0.001}
						min={0}
						value={newBasePrice}
						onChange={(e) => {
							setnewBasePrice(parseFloat(e.target.value));
						}}
						defaultValue={
							selectedTier[0] !== undefined
								? selectedTier[0].basePrice
								: undefined || ""
						}
					></Input>

					<h4>
						Additional Charge for Extended Retention (£/GB/month)
					</h4>
					<Input
						id="new-extended-price"
						type="number"
						step={0.001}
						min={0}
						style={{ marginBottom: 20 }}
						value={newExtendedAdditional}
						onChange={(e) => {
							setnewExtendedAdditional(
								parseFloat(e.target.value)
							);
						}}
						defaultValue={
							selectedTier[0] !== undefined
								? selectedTier[0].extendedAdditional
								: undefined || ""
						}
					></Input>

					<ButtonContainer>
						<Button
							label="Back"
							className="p-button-raised"
							style={{
								marginTop: 50,
								width: 100,
								fontFamily: "FujitsuSans",
							}}
							onClick={clearOptions}
						></Button>
						<Button
							id="edit-tier-button"
							label="Edit Tier"
							className="p-button-raised"
							style={{
								marginTop: 50,
								width: 100,
								fontFamily: "FujitsuSans",
							}}
							disabled={buttonDisabled}
							onClick={editTier}
						></Button>
					</ButtonContainer>
				</EditTierContent>
			</EditTierAlignment>
		</EditTierContainer>
	) : (
		<LoadingSpinner />
	);
};

export default EditTier;
