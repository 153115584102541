import { formatValue2Dp } from "./formatValue";
import { MonthlyCostState, Threshold } from "../types";

// Standard retention cost for tax model
export const calculateStandardCostTaxModel = ({
	idx,
	allThresholds,
	state,
	threshold,
}: {
	idx: number;
	allThresholds: Threshold[];
	state: MonthlyCostState;
	threshold: Threshold;
}): number => {
	let price = 0;
	if (idx === allThresholds.length - 1) {
		// Final threshold; no ranges
		price =
			(state.dataRequested - threshold.threshold) * threshold.basePrice;
	} else {
		// Price in between two thresholds (a range)
		const nextThreshold = allThresholds[idx + 1];
		price =
			(nextThreshold.threshold - threshold.threshold) *
			threshold.basePrice;
	}
	return formatValue2Dp(price);
};
