import React, { useEffect, useState } from "react";
import { Action, Model, MonthlyCostState, Threshold } from "../../types";
import {
	calcualteExtendedCostTierModel,
	calculateExtendedCostTaxModel,
	calculateStandardCostTaxModel,
	calcualteStandardCostTierModel,
	formatValue2Dp,
	formatValue3Dp,
} from "../../utils";
import { Important } from "./styles";
import { GB_TO_TB_FACTOR, toGBP, toGBP3Dp } from "../../globals";

interface ThresholdCosts {
	[key: string]: number;
}

interface ThresholdRowProps {
	state: MonthlyCostState;
	dispatch: React.Dispatch<Action>;
	allThresholds: Threshold[];
	threshold: Threshold;
	idx: number;
	model: Model;
	setStandardThresholdCostsExMargin: React.Dispatch<
		React.SetStateAction<ThresholdCosts>
	>;
	setExtendedThresholdCostsExMargin: React.Dispatch<
		React.SetStateAction<ThresholdCosts>
	>;
}

const ThresholdRow: React.FC<ThresholdRowProps> = ({
	state,
	allThresholds,
	threshold,
	idx,
	model,
	dispatch,
	setStandardThresholdCostsExMargin,
	setExtendedThresholdCostsExMargin,
}: ThresholdRowProps): JSX.Element => {
	const [monthlyCost, setMonthlyCost] = useState(0);
	const [standardMonthlyCost, setStandardMonthlyCost] = useState(0);
	const [additionalMonthlyCost, setAdditionalMonthlyCost] = useState(0);

	// Updates the total cost of standard retention and extended retention for place into sessionStorage
	// Await again says it's not used, but this only works with it there.
	useEffect(() => {
		const updateValues = async () => {
			await setStandardThresholdCostsExMargin((value: ThresholdCosts) => {
				return {
					...value,
					[threshold.tier]: standardMonthlyCost,
				};
			});
			await setExtendedThresholdCostsExMargin((value: ThresholdCosts) => {
				return {
					...value,
					[threshold.tier]: additionalMonthlyCost,
				};
			});
		};

		const resetValuesOnUnmount = async () => {
			await setStandardThresholdCostsExMargin((value: ThresholdCosts) => {
				return {
					...value,
					[threshold.tier]: 0,
				};
			});
			await setExtendedThresholdCostsExMargin((value: ThresholdCosts) => {
				return {
					...value,
					[threshold.tier]: 0,
				};
			});
		};
		updateValues();

		return () => {
			resetValuesOnUnmount();
		};
	}, [
		standardMonthlyCost,
		additionalMonthlyCost,
		setExtendedThresholdCostsExMargin,
		setStandardThresholdCostsExMargin,
		threshold.tier,
	]);

	// Calculate/Set cost values based on state - NOTE not including margin in calcs
	useEffect(() => {
		let standard = 0;
		let additional = 0;

		if (model === "tax") {
			standard = calculateStandardCostTaxModel({
				idx,
				allThresholds,
				threshold,
				state,
			});
			additional = calculateExtendedCostTaxModel({
				idx,
				allThresholds,
				threshold,
				state,
			});
		} else if (model === "tier") {
			standard = calcualteStandardCostTierModel({
				idx,
				allThresholds,
				threshold,
				state,
			});
			additional = calcualteExtendedCostTierModel({
				allThresholds,
				threshold,
				state,
			});
		}

		setStandardMonthlyCost(standard);
		setAdditionalMonthlyCost(additional);
		setMonthlyCost(formatValue2Dp(standard + additional));
	}, [state, allThresholds, idx, threshold, model]);

	// Update overall total on Monthly Costs page for each tier - return will reset unmounted thresholds to 0.
	useEffect(() => {
		dispatch({
			type: "dataCost",
			payload: monthlyCost,
			tier: threshold.tier,
		});
		return () => {
			dispatch({
				type: "dataCost",
				payload: 0,
				tier: threshold.tier,
			});
		};
	}, [monthlyCost, dispatch, threshold.tier]);

	// Threshold ranges - stored as Gb, displayed as Tb
	const getRangeTier = (): string => {
		if (idx === allThresholds.length - 1) {
			return `${formatValue3Dp(threshold.threshold * GB_TO_TB_FACTOR)} +`;
		} else {
			const nextTreshold = allThresholds[idx + 1];
			const range = `${formatValue3Dp(
				threshold.threshold * GB_TO_TB_FACTOR
			)} - ${formatValue3Dp(
				(nextTreshold.threshold - 1) * GB_TO_TB_FACTOR
			)}`;
			return range;
		}
	};
	const getRangeTax = (): string => {
		if (idx === allThresholds.length - 1) {
			return `${formatValue3Dp(
				(threshold.threshold + 1) * GB_TO_TB_FACTOR
			)} +`;
		} else {
			const nextTreshold = allThresholds[idx + 1];
			const range = `${formatValue3Dp(
				threshold.threshold * GB_TO_TB_FACTOR
			)} - ${formatValue3Dp(nextTreshold.threshold * GB_TO_TB_FACTOR)}`;
			return range;
		}
	};

	return (
		<tbody>
			<tr data-testid={`threshold-${threshold.tier}-row`}>
				<td>{model === "tier" ? getRangeTier() : getRangeTax()}</td>
				<td>{toGBP3Dp.format(threshold.basePrice)}</td>
				<td>{toGBP3Dp.format(threshold.extendedAdditional)}</td>
				{standardMonthlyCost !== 0 ? (
					<Important
						data-testid={`threshold-${threshold.tier}-standard-cost`}
					>
						{toGBP.format(standardMonthlyCost)}
					</Important>
				) : (
					<td
						data-testid={`threshold-${threshold.tier}-empty-standard-cost`}
					>
						{toGBP.format(standardMonthlyCost)}
					</td>
				)}
				{additionalMonthlyCost !== 0 ? (
					<Important
						data-testid={`threshold-${threshold.tier}-extended-cost`}
					>
						{toGBP.format(additionalMonthlyCost)}
					</Important>
				) : (
					<td
						data-testid={`threshold-${threshold.tier}-empty-extended-cost`}
					>
						{toGBP.format(additionalMonthlyCost)}
					</td>
				)}
				<Important
					data-testid={`threshold-${threshold.tier}-monthly-cost`}
				>
					{toGBP.format(monthlyCost)}
				</Important>
			</tr>
		</tbody>
	);
};

export default ThresholdRow;
