import React, { useState, useEffect } from "react";
import { getDiscounts } from "../../serviceWorker";
import { Button } from "primereact/button";
import ConfirmationPopUp from "../ConfirmationPopUp/ConfirmationPopUp";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { sortDB } from "../../utils/sortDb";
import { checkThreshold } from "../../utils/checkThreshold";
import { Threshold } from "../../types";
import {
	AddTierContainer,
	AddTierAlignment,
	AddTierContent,
	ButtonContainer,
	Input,
} from "./styles";

interface AddTierProps {
	clearOptions: () => void;
}

const AddTier: React.FC<AddTierProps> = ({ clearOptions }): JSX.Element => {
	const [discounts, setdiscounts] = useState<Array<Threshold>>([]);
	const [newThreshold, setnewThreshold] = useState<number>();
	const [newBasePrice, setnewBasePrice] = useState<number>();
	const [newadditionalCost, setnewadditionalCost] = useState<number>();
	const [open, setOpen] = React.useState(false);
	const [error, setError] = useState<boolean>(false);
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingRequest, setLoadingRequest] = useState<boolean>(true);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		getDiscounts().then((response) => {
			setdiscounts(response);
		});
		setnewThreshold(0);
		setnewBasePrice(0);
		setnewadditionalCost(0);
	};

	useEffect(() => {
		sortDB().then((response) => {
			setLoading(response);
		});

		getDiscounts().then((response) => {
			setdiscounts(response);
			if (response.length === 5) {
				setButtonDisabled(true);
			}
		});
	}, []);

	useEffect(() => {
		if (newBasePrice && newThreshold && newadditionalCost && !error) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [newBasePrice, newThreshold, newadditionalCost, error]);

	async function addNewTier() {
		handleClickOpen();
		try {
			await fetch(
				"https://ey6zu8r7jk.execute-api.eu-west-2.amazonaws.com/prod/getdiscounts",
				{
					body: JSON.stringify({
						tier: (discounts.length + 1).toString(),
						threshold: newThreshold?.toString(),
						basePrice: newBasePrice?.toString(),
						extendedAdditional: newadditionalCost?.toString(),
					}),
					method: "POST",
				}
			);
		} catch (error) {
			console.log(error);
		}
		setLoadingRequest(false);
	}

	return !loading ? (
		<AddTierContainer>
			<AddTierAlignment>
				<AddTierContent>
					<ConfirmationPopUp
						open={open}
						handleClose={handleClose}
						handleClickOpen={handleClickOpen}
						loading={loadingRequest}
						type="Added"
					/>

					<h1>Add Discount</h1>
					<input
						type="text"
						readOnly
						style={{ marginBottom: 20, textAlign: "center" }}
						value={
							discounts[0]
								? `Tier ${discounts.length + 1}`
								: "Fetching data...."
						}
					></input>

					<p>Threshold (GB)</p>
					<Input
						id="new-threshold"
						type="number"
						value={newThreshold}
						style={{ marginBottom: error ? 0 : undefined }}
						step={1}
						min={0}
						onChange={(e) => {
							const value = parseInt(e.target.value);
							setnewThreshold(value);

							//checks if value matches already existing threshold
							if (checkThreshold(value, discounts) === true) {
								setError(true);
								setButtonDisabled(true);
							} else if (
								checkThreshold(value, discounts) === false
							) {
								setError(false);
							}
						}}
					></Input>
					{error ? (
						<p
							id="error-message"
							style={{ color: "red", fontSize: 12 }}
						>
							This threshold already exists
						</p>
					) : (
						<div></div>
					)}

					<p>Standard Retention Charge (£/GB/month)</p>
					<Input
						id="new-base-price"
						type="number"
						value={newBasePrice}
						step={0.001}
						min={0}
						onChange={(e) => {
							setnewBasePrice(parseFloat(e.target.value));
						}}
					></Input>

					<p>Additional Charge for Extended Retention (£/GB/month)</p>
					<Input
						id="new-extended-price"
						type="number"
						value={newadditionalCost}
						step={0.001}
						min={0}
						onChange={(e) => {
							setnewadditionalCost(parseFloat(e.target.value));
						}}
					></Input>

					{discounts.length >= 5 ? (
						<p
							id="max-tier-message"
							style={{ color: "red", textAlign: "center" }}
						>
							You are currently at the maximum amount of discount
							tiers. <br /> Please remove a discount tier before
							adding a new one.
						</p>
					) : null}

					<ButtonContainer>
						<Button
							id="add-tier-back-button"
							label="Back"
							className="p-button-raised"
							style={{
								marginTop: 50,
								width: 100,
								fontFamily: "FujitsuSans",
							}}
							onClick={clearOptions}
						></Button>
						<Button
							id="add-tier-button"
							label="Add Tier"
							className="p-button-raised"
							disabled={buttonDisabled}
							style={{
								marginTop: 50,
								width: 100,
								fontFamily: "FujitsuSans",
							}}
							onClick={addNewTier}
						></Button>
					</ButtonContainer>
				</AddTierContent>
			</AddTierAlignment>
		</AddTierContainer>
	) : (
		<div>
			<LoadingSpinner />
		</div>
	);
};

export default AddTier;
