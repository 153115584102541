/* eslint-disable @typescript-eslint/no-var-requires */

import axios from "axios";
import { useEffect, useState } from "react";
import { MonthlyCostCategory } from "../types";
import { handleNetworkErrors } from "../utils/handleNetworkErrors";

interface MonthlyCategoriesApi {
	response?: {
		Items: MonthlyCostCategory[];
	};
	message?: string;
}

export const useAdditionalMonthlyCosts = (
	setThrowsError: React.Dispatch<React.SetStateAction<undefined>>
): MonthlyCostCategory[] | undefined => {
	const [additionalCosts, setAdditionalCosts] = useState<
		MonthlyCostCategory[] | undefined
	>();

	useEffect(() => {
		const getData = async () => {
			try {
				const {
					data,
				}: { data: MonthlyCategoriesApi } = await axios.get(
					"https://ey6zu8r7jk.execute-api.eu-west-2.amazonaws.com/prod/getmonthly"
				);
				if (!data.response) {
					const errMsg = data.message
						? data.message
						: "Something went wrong";
					throw Error(errMsg);
				}
				const items = data.response.Items;
				setAdditionalCosts(items);
			} catch (err: unknown) {
				handleNetworkErrors(err);
			}
		};
		// If in a testing calcs via Cypress, mock the added costs instead to ensure correct calculations always.
		if (process.env.REACT_APP_TESTING) {
			const {
				Items,
			}: {
				Items: MonthlyCostCategory[];
			} = require("../testingVars/additionalMonthlyCosts.json");
			setAdditionalCosts(Items);
		} else {
			getData().catch((err: Error) => {
				console.log(err);
				setThrowsError(() => {
					throw err;
				});
			});
		}
	}, [setThrowsError]);

	return additionalCosts;
};
