// Support email
export const contactEmail = "noel.wilkes-wells@fujitsu.com";

// Conversion factors
export const TB_TO_GB_FACTOR = 1000;
export const GB_TO_TB_FACTOR = 0.001;

// 15 seconds
export const NETOWORK_TIMEOUT = 15000;

export const toGBP = new Intl.NumberFormat("en-GB", {
	style: "currency",
	currency: "GBP",
	minimumFractionDigits: 2,
});

export const toGBP3Dp = new Intl.NumberFormat("en-GB", {
	style: "currency",
	currency: "GBP",
	minimumFractionDigits: 3,
});
