import React, { useContext } from "react";
import FujitsuLogoWhite from "../../assets/images/Fujitsu-Logo-White.png";
import SettingsIcon from "@material-ui/icons/Settings";
import { Link } from "react-router-dom";
import { AdminContext } from "../../context/AdminContext";
import Auth from "@aws-amplify/auth";
import { AuthContext } from "../../context/AuthContext";
import { AuthState } from "@aws-amplify/ui-components";

const Header: React.FC = (): JSX.Element => {
	const { admin } = useContext(AdminContext);
	const { authState } = useContext(AuthContext);

	async function signOut() {
		try {
			await Auth.signOut();
			sessionStorage.clear();
		} catch (error) {
			console.log("error signing out: ", error);
		}
	}

	return (
		<div
			style={{
				// backgroundColor: "#A30B1A",
				backgroundColor: "#962127",
				// backgroundColor: "#0a0a0a",
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
				width: "100%",
				color: "#DADADA",
			}}
		>
			<div
				style={{
					fontSize: "27px",
					margin: "0.6em",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<img
					alt={"Fujitsu logo white"}
					src={FujitsuLogoWhite}
					height="55"
					style={{
						transform: "translate(0px, -4px)",
						marginRight: "0.2em",
					}}
				/>
				<div style={{ display: "inline", verticalAlign: "top" }}>
					{" "}
					| HDM Sales Provocation Portal
				</div>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-around",
					alignItems: "center",
				}}
			>
				{admin ? (
					<div
						style={{
							cursor: "pointer",
							paddingLeft: 10,
							paddingRight: 10,
						}}
					>
						<div id="admin-settings">
							<Link aria-label="admin settings link" to="/admin">
								<SettingsIcon
									style={{ fontSize: "36px", color: "white" }}
								></SettingsIcon>
							</Link>
						</div>
					</div>
				) : null}
				{authState === AuthState.SignedIn && (
					<button
						style={{
							margin: "20px",
							padding: "5px",
							cursor: "pointer",
							fontSize: "1.3rem",
							border: "none",
							borderRadius: "15px",
							color: "#FFFFFF",
							backgroundColor: "#962127",
							fontWeight: 700,
						}}
						onClick={signOut}
					>
						Sign Out
					</button>
				)}
			</div>
		</div>
	);
};

export default Header;
