import { StorageItem } from "../types";

export const getMonthlyInitStateFromStorage = (): {
	data: StorageItem;
	extended: StorageItem;
	duration: StorageItem;
	margin: StorageItem;
} => {
	const data = sessionStorage.getItem("dataRequested");
	const extended = sessionStorage.getItem("extendedData");
	const duration = sessionStorage.getItem("contractDuration");
	const margin = sessionStorage.getItem("monthlyMargin");

	return { data, extended, duration, margin };
};
