import styled from "@emotion/styled";

export const H2 = styled.h2`
	background-color: #962127;
	border-top-left-radius: 13px;
	border-top-right-radius: 13px;
	padding: 0.3em;
	margin-top: 0;
	color: #ffffff;
	width: 100%auto;
`;
export const BreakdownMain = styled.div`
	-webkit-box-shadow: 1px 2px 3px 4px #ccc;
	-moz-box-shadow: 1px 2px 3px 4px #ccc;
	box-shadow: 1px 2px 3px 4px #ccc;
	border-radius: 15px;
	background-color: #ffe5dd;
	margin: 0.5em;
	min-height: 30em;
	grid-column: 1/5;
	grid-row: 2/3;
`;

export const StyledTable = styled.table`
	font-size: 1em;
	width: 90%;
	border-spacing: 0.8em;
`;
