import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactTooltip from "react-tooltip";
import { GB_TO_TB_FACTOR } from "../../globals";
import { InputDiv, InputLabel, NumberInput } from "../MonthlyCostInput/styles";

interface MonthlyCostInputExtendedDataProps {
	handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
	handleOnChangeData: ({
		e,
		decimalToFormatTo,
		dispatchType,
	}: {
		e: React.ChangeEvent<HTMLInputElement>;
		decimalToFormatTo: number;
		dispatchType: string;
	}) => void;
	dataRequested: number;
}

const MonthlyCostInputExtendedData = React.forwardRef<
	HTMLInputElement,
	MonthlyCostInputExtendedDataProps
>(
	(props, ref): JSX.Element => {
		const validateExtended = (
			e: React.ChangeEvent<HTMLInputElement>
		): void => {
			if (parseFloat(e.target.value) > parseFloat(e.target.max))
				e.target.value = e.target.max;
		};
		return (
			<InputDiv>
				<InputLabel htmlFor="extendedData">
					Data retained for over 35 days as per extended retention
					(TB)&nbsp;
					<FontAwesomeIcon
						data-tip
						data-for="extendedRetention"
						icon={faQuestionCircle}
					/>
				</InputLabel>
				<ReactTooltip
					id="extendedRetention"
					effect="solid"
					place="right"
				>
					Of the data being backed up for 35 days above, please enter
					the portion of that data which will also need to be retained
					for more than 35 days.
				</ReactTooltip>

				<NumberInput
					id="extendedData"
					step={GB_TO_TB_FACTOR}
					ref={ref}
					type="number"
					name="extendedData"
					defaultValue={0}
					min={0}
					onFocus={(e) => e.target.select()}
					max={props.dataRequested * GB_TO_TB_FACTOR}
					onBlur={(e) => props.handleBlur(e)}
					onChange={(e) => {
						validateExtended(e);
						props.handleOnChangeData({
							e,
							decimalToFormatTo: 3,
							dispatchType: "extended",
						});
					}}
				/>
			</InputDiv>
		);
	}
);

export default MonthlyCostInputExtendedData;
