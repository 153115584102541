import styled from "@emotion/styled";

export const CategoryGrid = styled.div`
	display: flex;
	justify-content: center;
	margin: 1.2em;
	border-radius: 20px;
	align-content: center;
	align-items: center;
	justify-items: center;
	background-color: #ffe5dd;
	margin-bottom: 6em;
`;

export const CategoryNameDiv = styled.div`
	border-radius: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	place-self: stretch stretch;
	background-color: #962127;
	color: #ffffff;
	margin-right: 1em;
`;

// export const Description = styled.h4`
// 	margin: 5px;
// 	grid-column: 2/3;
// 	width: 100%;
// 	justify-self: center;
// 	align-self: center;
// 	font-weight: normal;
// 	margin-bottom: 0.5em;
// 	margin-top: 0.5em;
// 	font-size: 1em;
// `;

export const CategoryTitle = styled.h3`
	margin: 0em;
	font-size: 1em;
`;

export const CostDiv = styled.div`
	justify-self: center;
	align-self: center;
`;
export const Cost = styled.h3`
	margin: 0;
	color: #5c7029;
	font-size: 1.2rem;
`;
