import React from "react";
import styled from "@emotion/styled";
import { contactEmail } from "../../globals";

const ErrorDiv = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

const ErrorInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 70%;
`;

const ErrorHeading = styled.h1`
	font-size: 3em;
`;

const ErrorDetail = styled.p`
	font-size: 1.4em;
`;

export const ErrorPage: React.FC<{ error: Error }> = ({
	error = Error("Unexpected Error Occured"),
}: {
	error: Error;
}): JSX.Element => {
	return (
		<ErrorDiv>
			<ErrorInfo>
				<ErrorHeading> Error Occured </ErrorHeading>
				<ErrorDetail>{error.message}</ErrorDetail>
				<div>
					<h2>Here's some things to try:</h2>
					<ul>
						<li>Reloading the page.</li>
						<li>Logging out and in again.</li>
						<li>Try opening the website in a different tab.</li>
						<li>
							Try a different web browser (FireFox, Edge, Chrome,
							etc).
						</li>
						<li>
							Write a furious email for the sake of catharsis.
						</li>
						<li>
							Please contact {contactEmail} if your issue isn't
							fixed after trying the above.
						</li>
					</ul>
				</div>
			</ErrorInfo>
		</ErrorDiv>
	);
};

export const errorHandler = (error: Error): void => {
	console.log("ERROR: " + error + "\n");
};
