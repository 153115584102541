import styled from "@emotion/styled";

export const OptionDescription = styled.h4`
	margin: 5px;
	grid-column: 2/3;
	width: 100%;
	justify-self: center;
	align-self: center;
	font-weight: normal;
	margin-bottom: 0.5em;
	margin-top: 0.5em;
	font-size: 1em;
`;

export const OptionUnitCost = styled.h4`
	margin: 0.5em;
	grid-column: 3/4;
	justify-self: center;
	width: 100%;
	align-self: center;
	font-size: 1em;
`;

export const OptionSelect = styled.div`
	grid-column: 4/5;
	width: 100%;
	justify-self: center;
	align-self: center;
`;
