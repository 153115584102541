import React from "react";
import { Button } from "primereact/button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@material-ui/core/CircularProgress";
interface ConfirmationProps {
	handleClickOpen: () => void;
	handleClose: () => void;
	open: boolean;
	type: string;
	loading?: boolean;
}

const ConfirmationPopUp: React.FC<ConfirmationProps> = ({
	handleClose,
	open,
	type,
	loading,
}) => {
	return !loading ? (
		<div>
			<Dialog
				id="confirmation"
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText
						id="alert-dialog-description"
						style={{
							display: "flex",
							justifyContent: "center",
							height: "10vh",
							width: "10vw",
						}}
					>
						<span
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								textAlign: "center",
							}}
						>
							{type === "standard" ? (
								<span>Cost Edited!</span>
							) : (
								<span>Discount Tier {type}!</span>
							)}

							<span>
								<FontAwesomeIcon
									id="check"
									icon={faCheckCircle}
									style={{
										width: "100%",
										height: "6vh",
										marginTop: 20,
									}}
								/>
							</span>
						</span>
					</DialogContentText>
				</DialogContent>
				<DialogActions style={{ justifyContent: "center" }}>
					<Button
						id="back-button"
						onClick={handleClose}
						color="primary"
					>
						Back
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	) : (
		<div>
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent
					style={{
						display: "flex",
						justifyContent: "center",
						width: "13vw",
						height: "10vw",
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							flexDirection: "column",
						}}
					>
						<span>
							<span>Sending Your Request</span>
						</span>

						<span style={{ textAlign: "center", marginTop: 30 }}>
							<CircularProgress />
						</span>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default ConfirmationPopUp;
