import React, { useEffect, useState } from "react";
import { Action, MonthlyCostState, Threshold } from "../../types";
import { getThresholds } from "../../utils";
import ThresholdRow from "../ThresholdRow/ThresholdRow";
import { useModel } from "../../hooks";
import MonthlyCategoryList from "../MonthlyCategoryList/MonthlyCategoryList";
import { BreakdownMain, H2, StyledTable } from "./styles";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RangeModal from "../RangeModal/RangeModal";
import ReactTooltip from "react-tooltip";
interface MonthlyCostBreakdownProps {
	state: MonthlyCostState;
	dispatch: React.Dispatch<Action>;
	thresholds: Threshold[];
}
interface ThresholdCosts {
	[key: string]: number;
}

const initialThresholdCosts = {
	"1": 0,
	"2": 0,
	"3": 0,
	"4": 0,
	"5": 0,
};

const MonthlyCostBreakdown: React.FC<MonthlyCostBreakdownProps> = ({
	state,
	dispatch,
	thresholds,
}: MonthlyCostBreakdownProps): JSX.Element => {
	const [
		standardThresholdCostsExMargin,
		setStandardThresholdCostsExMargin,
	] = useState<ThresholdCosts>(initialThresholdCosts);
	const [
		extendedThresholdCostsExMargin,
		setExtendedThresholdCostsExMargin,
	] = useState<ThresholdCosts>(initialThresholdCosts);

	const [, /* throwsError */ setThrowsError] = useState();

	const model = useModel(setThrowsError);
	const [openModal, setOpenModal] = React.useState(false);

	const handleModalOpen = () => {
		setOpenModal(true);
	};

	const handleModalClose = () => {
		setOpenModal(false);
	};

	// Saves these values into session storage for use in pdf
	useEffect(() => {
		const standardCostExMargin = Object.keys(standardThresholdCostsExMargin)
			.map((key: string) => standardThresholdCostsExMargin[key])
			.reduce((acc, curr) => acc + curr, 0);
		const extendedCostExMargin = Object.keys(extendedThresholdCostsExMargin)
			.map((key: string) => extendedThresholdCostsExMargin[key])
			.reduce((acc, curr) => acc + curr, 0);

		sessionStorage.setItem(
			"standardCostExMargin",
			standardCostExMargin.toString()
		);
		sessionStorage.setItem(
			"extendedCostExMargin",
			extendedCostExMargin.toString()
		);
	}, [standardThresholdCostsExMargin, extendedThresholdCostsExMargin]);

	const renderThresholds = () => {
		if (!model) return;

		// Only show relevant tiers
		const relevantThresholds = getThresholds({
			data: state.dataRequested,
			thresholds,
		});

		// Show all tiers - could implement if neater....
		return relevantThresholds.map((threshold, idx) => {
			return (
				<ThresholdRow
					key={threshold.threshold}
					state={state}
					dispatch={dispatch}
					allThresholds={relevantThresholds}
					threshold={threshold}
					idx={idx}
					model={model}
					setStandardThresholdCostsExMargin={
						setStandardThresholdCostsExMargin
					}
					setExtendedThresholdCostsExMargin={
						setExtendedThresholdCostsExMargin
					}
				/>
			);
		});
	};

	if (!model) return <div>Fetching cost model...</div>;
	if (model === "error") return <div>ERROR PAGE</div>;

	return (
		<BreakdownMain>
			<RangeModal open={openModal} handleClose={handleModalClose} />
			<H2>Cost Breakdown</H2>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<StyledTable>
						<colgroup>
							<col style={{ width: "18%" }} />
						</colgroup>

						<thead>
							<tr>
								<th>
									Range (TB)&nbsp;
									<FontAwesomeIcon
										data-tip
										data-for="rangeBreakdown"
										onClick={handleModalOpen}
										icon={faQuestionCircle}
									/>
									<ReactTooltip
										id="rangeBreakdown"
										effect="solid"
										place="right"
									>
										Click For All Discount Tiers
									</ReactTooltip>
								</th>
								<th>Standard Retention Charge (£/GB/month)</th>
								<th>
									Extended Retention Additional Charge
									(£/GB/month)
								</th>
								<th>Cost for Standard Retention (£/month)</th>
								<th>
									Additional Cost for Extended Retention
									(£/month)
								</th>
								<th>Total Cost (£/month)</th>
							</tr>
						</thead>
						{renderThresholds()}
					</StyledTable>
				</div>

				<div
					style={{
						width: "90%",
						borderTop: "1px solid black",
						marginTop: "2em",
					}}
				>
					<h2>Additional Monthly Costs</h2>
					<MonthlyCategoryList dispatch={dispatch} />
				</div>
			</div>
		</BreakdownMain>
	);
};

export default MonthlyCostBreakdown;
