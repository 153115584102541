import { formatValue2Dp } from "./formatValue";
import { MonthlyCostState, Threshold } from "../types";

// Standard retention for tiered model
export const calcualteStandardCostTierModel = ({
	idx,
	allThresholds,
	state,
	threshold,
}: {
	idx: number;
	allThresholds: Threshold[];
	state: MonthlyCostState;
	threshold: Threshold;
}): number => {
	let price = 0;

	// Only last threshold (one currently over) counted as per the model.
	if (idx === allThresholds.length - 1) {
		price = state.dataRequested * threshold.basePrice;
	}
	return formatValue2Dp(price);
};
