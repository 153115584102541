import React, { useContext, useEffect, useMemo, useState } from "react";
import "primeflex/primeflex.css";
import "../../styling/ButtonDemo.css";
import { Link, Redirect } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";
import AccessDenied from "../AccessDenied/AccessDenied";
import Category from "../../components/Category/Category";
import { Button } from "primereact/button";
import { useInitStartupData } from "../../hooks";
import { ButtonGroup, Cost, H1, H2, TotalCostDiv } from "./styles";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { toGBP } from "../../globals";

const buttonStyles = {
	width: "100px",
	fontFamily: "FujitsuSans",
	marginBottom: "50px",
};

const StartUp: React.FC = (): JSX.Element => {
	// DESIRED CATEGORIES TO BE DISPLAYED. IF CATEGORY NAME CHANGES, SO MUST THIS
	const desiredCategories = useMemo(
		() => [
			"Client Onboarding",
			"Project Management Cost",
			"Network Design and Deploy",
			"HDM Customer Design",
		],
		[]
	);

	const [total, setTotal] = useState<number>(0);
	const [totalExMargin, setTotalExMargin] = useState<number>(0);
	const [validContinue, setValidContinue] = useState<boolean>(false);
	const [, /* throwsError */ setThrowsError] = useState();

	const initData = useInitStartupData(desiredCategories, setThrowsError);

	// Sets totals
	useEffect(() => {
		sessionStorage.setItem("startupTotal", total.toString());
		sessionStorage.setItem(
			"startupTotalExMargin",
			totalExMargin.toString()
		);
	}, [total, totalExMargin]);

	const { authState } = useContext(AuthContext);
	const renderCategories = () => {
		if (initData) {
			return Object.keys(initData).map((category) => {
				const data = initData[category];
				return (
					<Category
						setTotal={setTotal}
						options={data}
						category={category}
						key={category}
						setTotalExMargin={setTotalExMargin}
					/>
				);
			});
		}
	};

	const checkValidContinue = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		e.preventDefault();
		if (initData) {
			const categories = Object.keys(initData);
			let invalid = false;
			categories.forEach((category) => {
				const insessionStorage = sessionStorage.getItem(
					`${category}-option`
				);
				if (!insessionStorage) invalid = true;
			});

			if (invalid) alert("Please select an option from each section.");
			if (!invalid) setValidContinue(true);
		}
	};

	if (validContinue) return <Redirect to="/monthly" />;

	if (!initData) return <LoadingSpinner />;
	if (!authState && initData) return <AccessDenied />;
	return (
		<div className="formContainer">
			<div className="formBody">
				<H1>Start-up Costs</H1>
				{renderCategories()}
				<TotalCostDiv>
					<div>
						<H2>Total Cost (Excl. margin):</H2>
						<Cost data-test-id="total-ex-margin">
							{toGBP.format(totalExMargin)}
						</Cost>
					</div>

					<div>
						<H2>Total Cost (Inc. margin):</H2>
						<Cost data-test-id="total">{toGBP.format(total)}</Cost>
					</div>
				</TotalCostDiv>
				<ButtonGroup>
					<Link to="/" style={{ textDecoration: "none" }}>
						<Button
							label="Back"
							className="p-button-raised"
							style={buttonStyles}
						/>
					</Link>
					<Button
						onClick={(e) => checkValidContinue(e)}
						label="Continue"
						className="p-button-raised"
						style={buttonStyles}
					/>
				</ButtonGroup>
			</div>
		</div>
	);
};

export default StartUp;
