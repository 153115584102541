import React from "react";

import { MarginDiv, MarginInput, MarginLabel } from "./styles";

interface MarginProps {
	category: string;
	categoryMargin: number;
	handleMarginChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Margin: React.FC<MarginProps> = ({
	category,
	categoryMargin,
	handleMarginChange,
}: MarginProps): JSX.Element => {
	return (
		<MarginDiv>
			<MarginLabel htmlFor={`${category}-margin`}>Margin (%)</MarginLabel>
			<MarginInput
				step={0.01}
				data-test-id={`${category}-margin`}
				id={`${category}-margin`}
				type="number"
				value={categoryMargin}
				onFocus={(e) => e.target.select()}
				onChange={(e) => {
					// Treat blank space as 0
					handleMarginChange(e);
				}}
				onBlur={(e) => {
					if (e.target.value === "") e.target.value = "0";
				}}
			/>
		</MarginDiv>
	);
};

export default Margin;
