import React, { useEffect } from "react";
import { toGBP } from "../../globals";
import { Action, MonthlyCostCategory } from "../../types";
import { formatValue2Dp } from "../../utils";
import {
	CategoryNameDiv,
	CategoryGrid,
	CategoryTitle,
	CostDiv,
	Cost,
} from "./styles";

interface MonthlyCategoryProps {
	item: MonthlyCostCategory;
	dispatch: React.Dispatch<Action>;
}

const MonthlyCategory: React.FC<MonthlyCategoryProps> = ({
	item,
	dispatch,
}): JSX.Element => {
	useEffect(() => {
		dispatch({
			type: "additionalCost",
			payload: item.UnitCost,
			name: item.Category,
		});
	}, [dispatch, item.Category, item.UnitCost]);

	return (
		<div>
			<CategoryGrid>
				<CategoryNameDiv>
					<CategoryTitle>{item.Category}</CategoryTitle>
				</CategoryNameDiv>
				<CostDiv>
					<Cost data-testid={`${item.Name}-cost`}>
						{toGBP.format(formatValue2Dp(item.UnitCost))}
					</Cost>
				</CostDiv>{" "}
			</CategoryGrid>
		</div>
	);
};

export default MonthlyCategory;
