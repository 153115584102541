import styled from "@emotion/styled";

export const H1 = styled.h1`
	background-color: #962127;
	border-top-left-radius: 13px;
	border-top-right-radius: 13px;
	padding: 0.3em;
	margin-top: -20px;
	color: #ffffff;
	margin-left: -20px;
	margin-right: -20px;
	text-align: center;
`;

export const SummaryDiv = styled.div`
	-webkit-box-shadow: 1px 2px 3px 4px #ccc;
	-moz-box-shadow: 1px 2px 3px 4px #ccc;
	box-shadow: 1px 2px 3px 4px #ccc;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	background-color: #ffe5dd;
	padding: 20px;
	border-radius: 15px;
	margin-top: 10%;
	display: flex;
	flex-direction: column;
	margin-bottom: 5%;
	height: 60%;
`;

export const H2 = styled.h2`
	font-size: 1.3rem;
	margin: 0.8em;
	padding-top: 0.8em;
	text-align: center;
	border-top: 2px solid black;
`;

export const Cost = styled.h3`
	color: #5c7029;
	font-size: 1.1em;
`;
