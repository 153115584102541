import { Threshold } from "../types";

export function checkThreshold(value: any, discounts: Threshold[]) {
	const filter = discounts.filter((option) => {
		return option.threshold === value;
	});
	if (filter[0]) {
		return true;
	} else {
		return false;
	}
}
