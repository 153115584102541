import styled from "@emotion/styled";

export const MonthlyCostContainer = styled.div`
	width: 75%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
`;

export const ButtonGroup = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 2em;
`;

export const MonthlyCostBody = styled.div`
	display: grid;
	grid-template-columns: 10% 40% 40% 10%;
	grid-template-rows: 50% 50%;
`;

export const H1 = styled.h1`
	font-size: 1.5em;
`;
