import React, { useContext, useReducer, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useThresholds } from "../../hooks";
import MonthlyCostInput from "../../components/MonthlyCostInput/MonthlyCostInput";
import { monthlyInitialState, monthlyReducer } from "../../reducers";
import MonthlyCostBreakdown from "../../components/MonthlyCostBreakdown/MonthlyCostBreakdown";
import MonthlyCostData from "../../components/MonthlyCostData/MonthlyCostData";
import { Link, Redirect } from "react-router-dom";
import { Button } from "primereact/button";
import {
	ButtonGroup,
	H1,
	MonthlyCostBody,
	MonthlyCostContainer,
} from "./styles";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import AccessDenied from "../AccessDenied/AccessDenied";

const buttonStyles = {
	width: "100px",
	fontFamily: "FujitsuSans",
	marginBottom: "50px",
};

const MonthlyCost: React.FC = (): JSX.Element => {
	const [, /* throwsError */ setThrowsError] = useState();

	const [state, dispatch] = useReducer(monthlyReducer, monthlyInitialState);
	const [redirect, setRedirect] = useState(false);
	const thresholds = useThresholds(setThrowsError);

	const { authState } = useContext(AuthContext);

	const checkValidContinue = (): void => {
		if (state.dataRequested <= 0)
			return alert(
				"You must input a positive value into the 'Data Requested' field."
			);
		if (state.dataRequested < 0)
			return alert(
				"You must input a positive value into the 'Amount for extended retention' field."
			);
		if (state.extendedData > state.dataRequested)
			return alert(
				"Data for extended retention cannot be greater than the total data desired."
			);
		if (state.contractDuration < 1)
			return alert("Contract must be at least 1 month");
		setRedirect(true);
	};

	if (!thresholds) return <LoadingSpinner />;
	if (redirect) return <Redirect to="/summary" />;
	if (thresholds && !authState) return <AccessDenied />;

	return (
		<MonthlyCostContainer>
			<H1>Monthly Costs</H1>
			<MonthlyCostBody>
				<MonthlyCostInput
					thresholds={thresholds}
					state={state}
					dispatch={dispatch}
				/>
				<MonthlyCostData state={state} />

				<MonthlyCostBreakdown
					thresholds={thresholds}
					state={state}
					dispatch={dispatch}
				/>
			</MonthlyCostBody>
			<ButtonGroup>
				<Link to="/startup" style={{ textDecoration: "none" }}>
					<Button
						data-testid="back-button"
						label="Back"
						className="p-button-raised"
						style={buttonStyles}
					/>
				</Link>
				<Button
					data-testid="continue-button"
					onClick={() => checkValidContinue()}
					label="Continue"
					className="p-button-raised"
					style={buttonStyles}
				/>
			</ButtonGroup>
		</MonthlyCostContainer>
	);
};

export default MonthlyCost;
