import React, { useCallback } from "react";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { MyDoc } from "../../components/PdfTemplate/PdfTemplate";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";

import { GB_TO_TB_FACTOR, toGBP } from "../../globals";
import { formatValue2Dp, formatValue3Dp } from "../../utils";
import { H1, H2, SummaryDiv, Cost } from "./styles";

const Summary: React.FC = (): JSX.Element => {
	const projectManagementCost = sessionStorage.getItem(
		"Project Management Cost-totalCost"
	)!;
	const onboardNewClientCost = sessionStorage.getItem(
		"Client Onboarding-totalCost"
	)!;
	const clientOnBoarding = formatValue2Dp(
		Number(projectManagementCost) + Number(onboardNewClientCost)
	);

	const startupIncMargin = formatValue2Dp(
		parseFloat(sessionStorage.getItem("startupTotal")!)
	);
	const monthlyIncMargin = formatValue2Dp(
		parseFloat(sessionStorage.getItem("monthlyIncMargin")!)
	);
	const contractDuration = parseInt(
		sessionStorage.getItem("contractDuration")!
	);
	const dataRequested = formatValue2Dp(
		parseFloat(sessionStorage.getItem("dataRequested")!) * GB_TO_TB_FACTOR
	).toString();
	const extendedRetention = formatValue2Dp(
		parseFloat(sessionStorage.getItem("extendedData")!) * GB_TO_TB_FACTOR
	).toString();
	const totalIncMargin = formatValue2Dp(
		Number(monthlyIncMargin) * Number(contractDuration)
	);
	const totalCost = formatValue2Dp(totalIncMargin + Number(startupIncMargin));

	const image = sessionStorage.getItem("image")!;
	const companyName = sessionStorage.getItem("companyName")!;
	const prevSolution = sessionStorage.getItem("prevSolution")!;
	const networkCost = sessionStorage.getItem(
		"Network Design and Deploy-totalCost"
	)!;
	const designCost = sessionStorage.getItem("HDM Customer Design-totalCost")!;

	// PDF
	const monthlyMargin =
		sessionStorage.getItem("monthlyMargin") !== null
			? parseFloat(sessionStorage.getItem("monthlyMargin")!)
			: 0;

	const extendedBackup =
		(sessionStorage.getItem("extendedCostExMargin") !== null
			? parseFloat(sessionStorage.getItem("extendedCostExMargin")!)
			: 0) *
		(1 + monthlyMargin / 100);

	const standardBackup =
		(sessionStorage.getItem("standardCostExMargin") !== null
			? parseFloat(sessionStorage.getItem("standardCostExMargin")!)
			: 0) *
		(1 + monthlyMargin / 100);

	const additionalCosts = JSON.parse(
		sessionStorage.getItem("additionalCosts")!
	);
	let networkConnect = 0;
	for (const item in additionalCosts) {
		networkConnect +=
			additionalCosts[item].cost * (1 + monthlyMargin / 100);
	}

	const requiredValues = [
		projectManagementCost,
		onboardNewClientCost,
		startupIncMargin,
		monthlyIncMargin,
		contractDuration,
		dataRequested,
		extendedRetention,
		totalIncMargin,
		totalCost,
		companyName,
		prevSolution,
		networkCost,
		designCost,
	];

	function isEmpty(str: string | any[] | number) {
		return !str || str === "NaN";
	}
	const validatePDF = useCallback(() => {
		for (const item in requiredValues) {
			if (isEmpty(requiredValues[item])) {
				return true;
			}
		}
		return false;
	}, [requiredValues]);

	React.useEffect(() => {
		validatePDF();
	}, [validatePDF]);

	const generatePdfDocument = async () => {
		const blob = await pdf(
			<MyDoc
				// home page
				logo={image} //Company Logo Link
				companyName={companyName} //Company Name
				prevSolution={prevSolution} //Name of Previous Solution
				// /startup page
				onboarding={clientOnBoarding} //New Client Onboarding Cost ()
				networkDesignDeploy={parseFloat(networkCost)} //Selected Network Connectivity Start Up Cost
				customerDesign={parseFloat(designCost)} //Selected Design Start Up Cost
				// /monthly cost page
				backupAmount={formatValue3Dp(parseFloat(dataRequested))} //Total Amount of Data Retained
				backupCost={standardBackup} //Total Cost of Data Retention For Less Than 35 Days
				extendedAmount={formatValue3Dp(parseFloat(extendedRetention))} //Total Amount of Data Retained For More Than 35 Day
				extendedCost={extendedBackup} //Total Cost of Data Retention For More Than 35 Days
				networkConnect={networkConnect} //Total Cost of Additional Monthly Costs - cost for 4 ports
				contractLength={contractDuration} //Contract Duration
				standardRetention={formatValue3Dp(parseFloat(dataRequested))} //Total amount of data retained
				extendedRetention={formatValue3Dp(
					parseFloat(extendedRetention)
				)} //Total amount of Extended Retention data to be backed up
				// /summary page
				totalStartup={startupIncMargin} //Total Cost of Startup
				totalMonthly={monthlyIncMargin} //Total Monthly Cost
				totalContract={totalCost} //TOtal contract cost including startup cost
				projectManagementCost={parseFloat(projectManagementCost)}
			/>
		).toBlob();
		saveAs(blob, "HDMSP.pdf");
	};
	return (
		<div
			style={{
				width: "30%",
				marginRight: "auto",
				marginLeft: "auto",
			}}
		>
			<SummaryDiv id="contain">
				<H1>Cost Breakdown</H1>
				<div>
					<table
						style={{
							width: "100%",
							tableLayout: "fixed",
							padding: "5%",
						}}
					>
						<tbody>
							<tr>
								<th colSpan={2} style={{ fontSize: 25 }}>
									Totals
								</th>
								<th colSpan={2} style={{ fontSize: 25 }}>
									Cost (£)
								</th>
							</tr>

							<tr>
								<th colSpan={2}>Start-up cost</th>
								<td
									colSpan={2}
									style={{
										textAlign: "center",
									}}
								>
									<Cost data-testid="startup-cost">
										{toGBP.format(startupIncMargin)}
									</Cost>
								</td>
							</tr>

							<tr>
								<th colSpan={2}>Monthly cost</th>
								<td
									colSpan={2}
									style={{
										textAlign: "center",
									}}
								>
									<Cost data-testid="monthly-cost">
										{toGBP.format(monthlyIncMargin)}
									</Cost>
								</td>
							</tr>

							<tr>
								<th colSpan={2} style={{ textAlign: "center" }}>
									Cost over contract duration
								</th>
								<td
									colSpan={2}
									style={{
										textAlign: "center",
									}}
								>
									<Cost data-testid="total-monthly-cost">
										{toGBP.format(totalIncMargin)}
									</Cost>
								</td>
							</tr>

							<tr>
								<th colSpan={2} style={{ textAlign: "center" }}>
									Total cost
									<br />
									(start-up costs + monthly costs over
									contract duration)
								</th>
								<td
									colSpan={2}
									style={{
										textAlign: "center",
									}}
								>
									<Cost data-testid="total-cost">
										{toGBP.format(totalCost)}
									</Cost>
								</td>
							</tr>
						</tbody>
					</table>
					<H2 data-testid="contract-duration">
						{contractDuration} Month Contract
					</H2>
				</div>
			</SummaryDiv>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					width: "100%",
					marginBottom: "15%",
				}}
			>
				<div>
					<Link
						to={{ pathname: "/monthly" }}
						style={{ textDecoration: "none" }}
					>
						<Button
							label="Back"
							className="p-button-raised"
							style={{
								marginTop: 10,
								width: 100,
								fontFamily: "FujitsuSans",
							}}
						/>
					</Link>
				</div>
				<div>
					<Button
						disabled={validatePDF()}
						onClick={generatePdfDocument}
						style={{
							marginTop: 10,
							width: 122,
							fontFamily: "FujitsuSans",
						}}
					>
						Click For PDF
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Summary;
