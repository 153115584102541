import { AuthState } from "@aws-amplify/ui-components";
import { createContext } from "react";

interface AuthContextProps {
	authState: AuthState | undefined;
	setAuthState?: React.Dispatch<React.SetStateAction<AuthState | undefined>>;
}

export const AuthContext = createContext<AuthContextProps>({
	authState: undefined,
});
