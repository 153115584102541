import { Action, AdditionalCosts, MonthlyCostState } from "../types";

// Cost of any additional costs for monthly cost page (e.g network connectivity)
export const calculateAdditionalCost = (
	state: MonthlyCostState,
	action: Action
): {
	newAdditionalCost: number;
	newAdditionalCosts: AdditionalCosts[];
} => {
	const newAddedCostItem: AdditionalCosts = {
		name: action.name!,
		cost: action.payload,
	};

	// Create new cost arr with each item as [ {name, cost} ]
	const newAdditionalCosts = [
		...state.additionalMonthlyCosts,
		newAddedCostItem,
	];

	const costArr = newAdditionalCosts.map((item) => item.cost);
	const newAdditionalCost: number = costArr.reduce(
		(acc: number, curr: number) => acc + curr,
		0
	);

	return { newAdditionalCost, newAdditionalCosts };
};
