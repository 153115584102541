import styled from "@emotion/styled";

export const H2 = styled.h2`
	background-color: #962127;
	border-top-left-radius: 13px;
	border-top-right-radius: 13px;
	padding: 0.3em;
	margin-top: 0;
	margin-bottom: 0;
	color: #ffffff;
`;

export const InputMain = styled.div`
	border-radius: 15px;
	background-color: #ffe5dd;
	margin: 0.5em;
	height: 27rem;
	grid-column: 2/3;
	grid-row: 1/2;
	-webkit-box-shadow: 1px 2px 3px 4px #ccc;
	-moz-box-shadow: 1px 2px 3px 4px #ccc;
	box-shadow: 1px 2px 3px 4px #ccc;
`;

export const InputDiv = styled.div`
	margin: 0.8em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const InputLabel = styled.label`
	font-size: 1.05em;
	font-weight: 700;
	margin-bottom: 0.6rem;
`;

export const NumberInput = styled.input`
	margin: 0.05em;
	border-radius: 5px;
	border: 2px solid black;
	font-size: 1.2em;
	text-align: center;
	width: 40%;
	-moz-appearance: textfield;
	&:-webkit-inner-spin-button,
	&:-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;
