import React from "react";
import { Threshold } from "../../types";
import { GB_TO_TB_FACTOR, toGBP3Dp } from "../../globals";

interface BreakdownRowProps {
	allThresholds: Threshold[];
	threshold: Threshold;
	idx: number;
}

const ThresholdRow: React.FC<BreakdownRowProps> = ({
	allThresholds,
	threshold,
	idx,
}: BreakdownRowProps): JSX.Element => {
	// Threshold ranges - stored as Gb, displayed as Tb
	const getRange = (): string => {
		if (idx === allThresholds.length - 1) {
			return `${Math.round(
				threshold.threshold * GB_TO_TB_FACTOR + 1 * GB_TO_TB_FACTOR
			)} +`;
		} else {
			const nextTreshold = allThresholds[idx + 1];
			const range = `${Math.round(
				threshold.threshold * GB_TO_TB_FACTOR
			)} - ${Math.round(nextTreshold.threshold * GB_TO_TB_FACTOR)}`;
			return range;
		}
	};

	return (
		<tbody>
			<tr style={{ textAlign: "center" }}>
				<td>{getRange()}</td>
				<td>{toGBP3Dp.format(threshold.basePrice)}</td>
				<td>{toGBP3Dp.format(threshold.extendedAdditional)}</td>
			</tr>
		</tbody>
	);
};

export default ThresholdRow;
