import styled from "@emotion/styled";

export const MarginDiv = styled.div`
	grid-column: 3/4;
	display: flex;
	flex-direction: column;
	width: 60%;
	margin: 0.5em;
`;

export const MarginInput = styled.input`
	border-radius: 5px;
	place-self: center;
	text-align: right;
	padding: 0.3em;
	margin: 0.3em;
	border: 2px solid black;
	font-size: 1em;
	-moz-appearance: textfield;
	&:-webkit-inner-spin-button,
	&:-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	width: 60%;
`;

export const MarginLabel = styled.label`
	font-weight: normal;
	font-size: 1em;
`;
