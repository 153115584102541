import { MonthlyCostState, Threshold } from "../types";
import { formatValue2Dp } from "./formatValue";

// Extended retention calculation for tax model
export const calculateExtendedCostTaxModel = ({
	idx,
	state,
	threshold,
	allThresholds,
}: {
	idx: number;
	state: MonthlyCostState;
	threshold: Threshold;
	allThresholds: Threshold[];
}): number => {
	let price = 0;
	if (state.extendedData > threshold.threshold) {
		if (idx === allThresholds.length - 1) {
			// Final threshold - no ranges to deal with
			price =
				(state.extendedData - threshold.threshold) *
				threshold.extendedAdditional;
		} else {
			const nextThreshold = allThresholds[idx + 1];

			// Maxed out threshold
			if (state.extendedData >= nextThreshold.threshold) {
				price =
					(nextThreshold.threshold - threshold.threshold) *
					threshold.extendedAdditional;

				// Partially maxed out threshold
			} else {
				price =
					(state.extendedData - threshold.threshold) *
					threshold.extendedAdditional;
			}
		}
	}
	return formatValue2Dp(price);
};
