import React from "react";
import { FileUpload } from "primereact/fileupload";

const SelectionPage: React.FC = (): JSX.Element => {
	//Function to convert image object to base64 link and store it in session storage
	const myUploader = ({ files }: any) => {
		const [file] = files;
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = function () {
			const base64data = reader.result;
			sessionStorage.setItem("image", base64data as string);
		};
	};

	return (
		<div style={{ display: "0.5em" }}>
			<FileUpload
				id="fileUpload"
				name="demo[]"
				url="./sendImage"
				accept="image/*"
				chooseLabel="Browse"
				auto
				customUpload
				uploadHandler={myUploader}
			></FileUpload>
		</div>
	);
};

export default SelectionPage;
