import React, { useContext } from "react";
import AdminOptions from "../../components/AdminOptions/AdminOptions";
import { AdminContext } from "../../context/AdminContext";
import AccessDenied from "../AccessDenied/AccessDenied";

const Admin: React.FC = (): JSX.Element => {
	const { admin } = useContext(AdminContext);

	return admin ? <AdminOptions /> : <AccessDenied page={"admin"} />;
};
export default Admin;
