import React, { useEffect, useState } from "react";
import { toGBP } from "../../globals";
import { MonthlyCostState } from "../../types";
import { formatValue2Dp } from "../../utils";
import { Cost, CostGroup, DataMain, H2, StyledH3 } from "./styles";

interface MonthlyCostDataProps {
	state: MonthlyCostState;
}

const MonthlyCostData: React.FC<MonthlyCostDataProps> = ({
	state,
}: MonthlyCostDataProps): JSX.Element => {
	const [monthlyExMargin, setMonthlyExMargin] = useState(0);
	const [monthlyIncMargin, setMonthlyIncMargin] = useState(0);
	const [totalExMargin, setTotalExMargin] = useState(0);
	const [totalIncMargin, setTotalIncMargin] = useState(0);

	useEffect(() => {
		const totalEx = formatValue2Dp(
			state.totalMonthlyCost * state.contractDuration
		);
		const totalInc = formatValue2Dp(
			state.totalMonthlyCost *
				state.contractDuration *
				(1 + state.margin / 100)
		);
		const monthlyEx = formatValue2Dp(state.totalMonthlyCost);
		const monthlyInc = formatValue2Dp(
			state.totalMonthlyCost * (1 + state.margin / 100)
		);

		setTotalExMargin(totalEx);
		setTotalIncMargin(totalInc);
		setMonthlyExMargin(monthlyEx);
		setMonthlyIncMargin(monthlyInc);
	}, [state.totalMonthlyCost, state.contractDuration, state.margin]);

	// Stores values in local storage
	useEffect(() => {
		sessionStorage.setItem("dataRequested", state.dataRequested.toString());
		sessionStorage.setItem("extendedData", state.extendedData.toString());
		sessionStorage.setItem(
			"contractDuration",
			state.contractDuration.toString()
		);
		sessionStorage.setItem("monthlyMargin", state.margin.toString());
		sessionStorage.setItem("monthlyExMargin", monthlyExMargin.toString());
		sessionStorage.setItem("monthlyIncMargin", monthlyIncMargin.toString());
	}, [state, monthlyExMargin, monthlyIncMargin]);

	return (
		<DataMain>
			<H2>Total Costs</H2>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<CostGroup>
					<StyledH3>Monthly Cost (Ex. margin):</StyledH3>
					<Cost data-testid="monthly-ex-margin">
						{toGBP.format(monthlyExMargin)}
					</Cost>
				</CostGroup>
				<CostGroup>
					<StyledH3>Monthly Cost (Inc. margin):</StyledH3>
					<Cost data-testid="monthly-inc-margin">
						{toGBP.format(monthlyIncMargin)}
					</Cost>
				</CostGroup>
				<CostGroup>
					<StyledH3>
						Cost for {state.contractDuration} Month(s) (Ex. margin):
					</StyledH3>
					<Cost data-testid="total-ex-margin">
						{toGBP.format(totalExMargin)}
					</Cost>
				</CostGroup>
				<CostGroup style={{ borderBottom: "none" }}>
					<StyledH3>
						Cost for {state.contractDuration} Month(s) (Inc.
						margin):
					</StyledH3>
					<Cost data-testid="total-inc-margin">
						{toGBP.format(totalIncMargin)}
					</Cost>
				</CostGroup>
			</div>
		</DataMain>
	);
};

export default MonthlyCostData;
