import React, { MutableRefObject, useEffect, useRef } from "react";
import { toGBP } from "../../globals";
import { Option } from "../../types";
import { OptionDescription, OptionSelect, OptionUnitCost } from "./styles";

interface OptionProps extends Option {
	setCategoryCost: React.Dispatch<React.SetStateAction<number>>;
	category: string;
}

const SelectionOption: React.FC<OptionProps> = ({
	Name,
	UnitCost,
	category,
	Description,
	setCategoryCost,
}: OptionProps): JSX.Element => {
	const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

	const handleClick = () => {
		setCategoryCost(UnitCost);
		sessionStorage.setItem(`${category}-option`, Name);
	};

	// Gets selection from sessionStorage
	useEffect(() => {
		const savedSelection = sessionStorage.getItem(`${category}-option`);
		if (savedSelection === Name) {
			inputRef.current.click();
		}
	}, [category, inputRef, Name]);

	return (
		<>
			<OptionDescription>{Description}</OptionDescription>
			<OptionUnitCost> {toGBP.format(UnitCost)}</OptionUnitCost>
			<OptionSelect>
				<label htmlFor={Name}>Select: </label>
				<input
					ref={inputRef}
					type="radio"
					name={category}
					value={UnitCost}
					id={Name}
					onClick={handleClick}
				/>
			</OptionSelect>
		</>
	);
};

export default SelectionOption;
