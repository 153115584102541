import Amplify from "aws-amplify";
import React from "react";
import Pathing from "./pathing";

import awsconfig from "./aws-exports";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

Amplify.configure(awsconfig);

const App: React.FC = (): JSX.Element => {
	return <Pathing />;
};

export default App;
