import { Action, MonthlyCostState } from "../types";

// Creates new monthly threshold costs object based on the incoming action
// returns the new threshold costs object, and the total value of monthly cost
export const calculateNewDataCost = (
	state: MonthlyCostState,
	action: Action
) => {
	const newMonthlyThresholdCosts = {
		...state.monthlyThresholdCostTotals,
	};
	newMonthlyThresholdCosts[action.tier!] = action.payload;
	const thresholds = Object.keys(newMonthlyThresholdCosts);
	const costArr = thresholds.map(
		(threshold) => (newMonthlyThresholdCosts as any)[threshold]
	);
	const newTotalDataCost: number = costArr.reduce(
		(acc: number, curr: number) => acc + curr,
		0
	);
	return { newTotalDataCost, newMonthlyThresholdCosts };
};
