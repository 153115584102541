import { Threshold } from "../types";

// Gets the highest threshold of the relevant ones.
export const getMaxThresholds = ({
	thresholds,
}: {
	thresholds: Threshold[];
}): number => {
	const sortedThresholds = thresholds.sort(
		(a, b) => a.threshold - b.threshold
	);
	const threshold = sortedThresholds[sortedThresholds.length - 1].threshold;
	return threshold;
};
