import React, { useState } from "react";
import { useAdditionalMonthlyCosts } from "../../hooks";
import { Action, MonthlyCostCategory } from "../../types";
import MonthlyCategory from "../MonthlyCategory/MonthlyCategory";

interface iProps {
	dispatch: React.Dispatch<Action>;
}

const MonthlyCategoryList: React.FC<iProps> = ({
	dispatch,
}: iProps): JSX.Element => {
	const [, /* throwsError */ setThrowsError] = useState();
	const additionalCosts:
		| MonthlyCostCategory[]
		| undefined = useAdditionalMonthlyCosts(setThrowsError);

	const renderAdditionalCategories = () => {
		if (!additionalCosts) return;
		return additionalCosts.map((item) => {
			return (
				<MonthlyCategory
					key={item.Name}
					item={item}
					dispatch={dispatch}
				/>
			);
		});
	};

	return (
		<div data-testid="additional-costs-div">
			{" "}
			{renderAdditionalCategories()}
		</div>
	);
};

export default MonthlyCategoryList;
