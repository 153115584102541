import React, { useState } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import AuthStateApp from "./pages/AuthStateApp/AuthStateApp";
import StartUp from "./pages/StartUp/StartUp";
import MonthlyCost from "./pages/MonthlyCost/MonthlyCost";
import Summary from "./pages/Summary/Summary";
import Admin from "./pages/Admin/Admin";
import Footer from "./components/Footer/Footer";
import { LastLocationProvider } from "react-router-last-location";
import { UserContext } from "./context/UserContext";
import { AuthState } from "@aws-amplify/ui-components";
import { AuthContext } from "./context/AuthContext";
import { AdminContext } from "./context/AdminContext";
import Header from "./components/Header/Header";
import CustomerInfo from "./pages/customerInfo/customerInfo";
import {
	errorHandler,
	ErrorPage,
} from "./components/ErrorBoundary/ErrorBoundary";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import Privacy from "./pages/Privacy/Privacy";

const Pathing: React.FC = (): JSX.Element => {
	const [user, setUser] = useState<object | undefined>(undefined);
	const [authState, setAuthState] = React.useState<AuthState>();
	const [admin, setAdmin] = useState<boolean>(false);

	return (
		<UserContext.Provider value={{ user, setUser }}>
			<AuthContext.Provider value={{ authState, setAuthState }}>
				<AdminContext.Provider value={{ admin, setAdmin }}>
					<Router>
						<LastLocationProvider>
							<Header />
							<ErrorBoundary
								FallbackComponent={ErrorPage}
								onError={errorHandler}
							>
								<div
									style={{
										backgroundColor: "#FBFBFB",
										overflow: "auto",
										minHeight: "calc(100vh - 137px)", // Match footer height + padding
									}}
								>
									<Switch>
										{" "}
										<Route
											exact
											path="/privacy"
											component={Privacy}
										></Route>
										<AuthStateApp>
											<Switch>
												<Route
													exact
													path="/"
													component={CustomerInfo}
												></Route>
												<Route
													exact
													path="/startup"
													component={StartUp}
												></Route>
												<Route
													exact
													path="/monthly"
													component={MonthlyCost}
												></Route>
												<Route
													exact
													path="/summary"
													component={Summary}
												></Route>
												<Route
													exact
													path="/admin"
													component={Admin}
												></Route>
												<Route
													path="/"
													component={PageNotFound}
												></Route>
											</Switch>
										</AuthStateApp>
									</Switch>
								</div>

								<Footer />
							</ErrorBoundary>
						</LastLocationProvider>
					</Router>
				</AdminContext.Provider>
			</AuthContext.Provider>
		</UserContext.Provider>
	);
};

export default Pathing;
