import React from "react";
import ReactPDF, {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Font,
	Image,
} from "@react-pdf/renderer";
import RegularFont from "../../assets/fonts/FujitsuSansRegular.ttf";
import ItalicFont from "../../assets/fonts/FujitsuSansRegIta.ttf";
import BoldFont from "../../assets/fonts/FujitsuSansBold.ttf";
import BoldItalicFont from "../../assets/fonts/FujitsuSansBoldIta.ttf";
import DataImg from "../../assets/images/Data.jpg";
import DataManagementImg from "../../assets/images/DataManagement.jpg";
import FujitsuLogo from "../../assets/images/Fujitsu-Logo-Red.png";
import GenericLogo from "../../assets/images/GenericLogo.jpg";
import StairsImg from "../../assets/images/Stairs.jpg";
import { toGBP } from "../../globals";

Font.register({
	family: "FujitsuSans",
	fonts: [
		{ src: RegularFont },
		{ src: ItalicFont, fontStyle: "italic" },
		{ src: BoldFont, fontWeight: 700 },
		{ src: BoldItalicFont, fontStyle: "italic", fontWeight: 700 },
	],
});

//Removes hyphen breaks from line endings
Font.registerHyphenationCallback((word) => {
	// Return entire word as unique part
	return [word];
});

const heightStyles = {
	rowOne: "7%",
	rowTwo: "3%",
	rowThree: "65%",
	rowFour: "20%",
	rowFive: "5%",
};

//Defines the styles applied to each area of the PDF
const styles = StyleSheet.create({
	page: {
		flexDirection: "column",
		backgroundColor: "#FFFFFF",
		fontFamily: "FujitsuSans",
	},
	rowFlex: {
		flexDirection: "row",
	},
	image: {
		width: "80%",
		height: "auto",
		objectFit: "contain",
	},
	customerLogo: {
		padding: "2%",
		maxWidth: "30%",
		flexGrow: 1,
	},
	docTitle: {
		padding: "2%",
		maxWidth: "55%",
		flexGrow: 1,
		color: "#A30B1A",
		textAlign: "center",
		fontSize: 36,
	},
	logo: {
		padding: "2%",
		maxWidth: "15%",
		flexGrow: 1,
	},
	slogan: {
		padding: "1%",
		maxWidth: "100%",
		flexGrow: 1,
		color: "#A30B1A",
		textAlign: "center",
		fontSize: 26,
		fontStyle: "italic",
	},
	leftColumn: {
		padding: "2%",
		maxWidth: "35%",
		flexGrow: 1,
		fontSize: 17,
		textAlign: "justify",
	},
	centreColumn: {
		padding: "2%",
		maxWidth: "35%",
		flexGrow: 1,
		fontSize: 17,
		textAlign: "justify",
	},
	rightColumn: {
		padding: "2%",
		maxWidth: "30%",
		flexGrow: 1,
		fontSize: 15,
		textAlign: "justify",
	},
	centreColumnHeadings: {
		padding: "1%",
		fontWeight: 700,
		backgroundColor: "#A30B1A",
		fontSize: 20,
		textAlign: "center",
		color: "#FAFAFA",
	},
	rightColumnHeadings: {
		padding: "2%",
		fontWeight: 700,
		fontStyle: "italic",
		fontSize: 20,
		textAlign: "left",
		color: "#A30B1A",
	},
	signoffBanner: {
		padding: "0%",
		backgroundColor: "#A30B1A",
		minWidth: "3%",
		maxWidth: "3%",
		flexGrow: 1,
	},
	signoffStatement: {
		padding: "2%",
		maxWidth: "97%",
		flexGrow: 1,
	},
	copyright: {
		padding: "2%",
		maxWidth: "65%",
		flexGrow: 1,
		fontSize: 12,
		color: "#ABABAB",
	},
	fujitsuMessage: {
		padding: "2%",
		maxWidth: "35%",
		flexGrow: 1,
		fontSize: 24,
		textAlign: "right",
	},
});

interface MyDocProps {
	logo: string;
	companyName: string;
	prevSolution: string;
	onboarding: number;
	networkConnect: number;
	customerDesign: number;
	backupAmount: number;
	backupCost: number;
	extendedAmount: Number;
	contractLength: number;
	networkDesignDeploy: number;
	standardRetention: Number;
	extendedRetention: Number;
	extendedCost: number;
	totalStartup: number;
	totalMonthly: number;
	totalContract: number;
	projectManagementCost: number;
}

//Defines the contents of the PDF
export const MyDoc: React.FC<MyDocProps> = (props: MyDocProps): JSX.Element => (
	<Document>
		{/* ruler verticalRulerSteps="7%" horizontalRulerSteps="10%" */}
		<Page size="A2" style={styles.page}>
			<View
				style={[
					styles.rowFlex,
					{
						minHeight: heightStyles.rowOne,
						maxHeight: heightStyles.rowOne,
						alignItems: "center",
					},
				]}
			>
				<View style={styles.customerLogo}>
					<Image
						allowDangerousPaths={true}
						style={styles.image}
						src={props.logo != null ? props.logo : GenericLogo}
					/>
				</View>
				<View style={styles.docTitle}>
					<Text>Hybrid Data Management (HDM)</Text>
				</View>
				<View style={styles.logo}>
					<Image
						allowDangerousPaths={true}
						style={styles.image}
						src={FujitsuLogo}
					/>
				</View>
			</View>
			<View
				style={[
					styles.rowFlex,
					{
						minHeight: heightStyles.rowTwo,
						maxHeight: heightStyles.rowTwo,
					},
				]}
			>
				<View style={styles.slogan}>
					<Text>
						Go beyond commoditised backup services with Fujitsu’s
						Hybrid Data Management!
					</Text>
				</View>
			</View>
			<View
				style={[
					styles.rowFlex,
					{
						minHeight: heightStyles.rowThree,
						maxHeight: heightStyles.rowThree,
					},
				]}
			>
				<View style={styles.leftColumn}>
					<Text style={{ margin: 5, marginTop: 0 }}>
						Hosted upon central, always-on infrastructure in Fujitsu
						Data Centres, consumers of the service can be connected
						via a private network connection or via the Internet (or
						both), and may therefore be resident in Fujitsu Data
						Centres or elsewhere. The service is utility charged,
						based on the amount of front-end data that is subject to
						backup and archive on the consumer device. Standard
						pricing is based on fixed retention, which enables
						predictable cost estimation for the {props.companyName}{" "}
						account.
					</Text>
					<Text style={{ margin: 5 }}>
						The service can complement or replace traditional
						on-site backup and archive solutions with one that is
						secure, off-site by design, and with the scale and
						flexibility afforded by cloud capabilities. The service
						provides simple to consume features, available to system
						administrators via a service console, and can be
						configured to varying levels of complexity and
						integration within the {props.companyName} enterprise
						infrastructure. Data residency can be assured using
						Fujitsu data centres to host backup data in multiple
						locations. Alternatively, customer backup data can also
						be hosted in multiple regions within the public cloud,
						with both methods ensuring accessibility and protection
						against loss and/or corruption.
					</Text>
					<Text style={{ margin: 5 }}>
						<Text style={{ fontWeight: 700 }}>HDM Backup </Text>–
						Backup & Recovery is available for physical, virtual and
						public cloud hosted servers, containers, and end devices
						within the {props.companyName} estate.
					</Text>
					<Text style={{ margin: 5 }}>
						<Text style={{ fontWeight: 700 }}>HDM Archive </Text>–
						The HDM Archive option is fully integrated with Backup &
						Recovery, using a single look and feel. This integration
						enables a single-pass operation on the data for both
						Backup and Archiving.
					</Text>
					<Text style={{ margin: 5 }}>
						<Text style={{ fontWeight: 700 }}>
							Integrated Authentication{" "}
						</Text>
						– The service can be configured to integrate with the{" "}
						{props.companyName} Active Directory for the purpose of
						user authentication and role-based access capabilities,
						providing a seamless single sign-on, multi-factor
						authentication experience.
					</Text>
					<Text style={{ margin: 5 }}>
						<Text style={{ fontWeight: 700 }}>Reporting </Text>– HDM
						offers a highly comprehensive and feature-rich set of
						self-service reporting functions.
					</Text>
					<Text style={{ margin: 5 }}>
						<Text style={{ fontWeight: 700 }}>
							Integrated Security{" "}
						</Text>
						– HDM proactively detects unusual data behaviour and
						anomalies.
					</Text>
				</View>
				<View style={styles.centreColumn}>
					<Text style={styles.centreColumnHeadings}>
						Running Costs
					</Text>
					<Text style={{ margin: 5 }}>
						{props.companyName} would be charged for each front-end
						GB of data that is protected by the service. An
						additional charge is applied to all data with extended
						retention requirements i.e. {">"}35 days. Archived data
						is charged separately and is again based on GB of
						protected data. A monthly service charge for direct
						network connections to the service is also applied.
						There are no additional charges for benefits such as
						dual data centre replication, software agents,
						licensing, ingress/egress transactions, recoveries and
						the lifecycle management of hardware and software.
					</Text>
					<Text style={styles.centreColumnHeadings}>
						Supported Systems
					</Text>
					<Text style={{ fontWeight: 700, margin: 5 }}>
						Operating Systems and Virtualization:
					</Text>
					<Text style={{ marginLeft: 5 }}>
						{" "}
						- Microsoft Windows Server/Microsoft Hyper-V
					</Text>
					<Text style={{ marginLeft: 5 }}> - VMWare</Text>
					<Text style={{ marginLeft: 5 }}> - IBM Power</Text>
					<Text style={{ marginLeft: 5 }}> - Linux</Text>
					<Text style={{ marginLeft: 5 }}> - Citrix</Text>
					<Text style={{ marginLeft: 5 }}> - Solaris SPARC</Text>
					<Text style={{ marginLeft: 5 }}>
						{" "}
						- Docker containers and images
					</Text>
					<Text style={{ marginLeft: 5 }}> - Kubernetes</Text>
					<Text style={{ fontWeight: 700, margin: 5 }}>Cloud</Text>
					<Text style={{ marginLeft: 5 }}>
						{" "}
						- Azure/AWS/Google/Oracle
					</Text>
					<Text style={{ marginLeft: 5 }}>
						{" "}
						- SaaS Services (e.g. O365, Salesforce)
					</Text>
					<Text style={{ fontWeight: 700, margin: 5 }}>
						Desktop/Laptop Clients:
					</Text>
					<Text style={{ marginLeft: 5 }}> - Microsoft Windows</Text>
					<Text style={{ marginLeft: 5 }}> - Apple OS X / MacOS</Text>
					<Text style={{ fontWeight: 700, margin: 5 }}>
						Also Supported:
					</Text>
					<Text style={{ marginLeft: 5 }}>
						{" "}
						- Microsoft Exchange, SharePoint, SQL Server
					</Text>
					<Text style={{ marginLeft: 5 }}>
						{" "}
						- Oracle/MySQL/DB2/IBM Notes/Domino/SAP
					</Text>
					<View
						style={
							{
								margin: "5",
							} as ReactPDF.Style
						}
					>
						<Image
							allowDangerousPaths={true}
							style={styles.image}
							src={DataManagementImg}
						/>
					</View>
				</View>
				<View style={styles.rightColumn}>
					<View
						style={{
							border: "2pt",
							height: "97%",
							borderColor: "#A30B1A",
							backgroundColor: "#DEDEDE",
						}}
					>
						<Text style={styles.rightColumnHeadings}>
							Next Steps...
						</Text>
						<Image
							allowDangerousPaths={true}
							style={styles.image}
							src={StairsImg}
						/>
						<Text style={{ margin: 5 }}>
							An initial consultative assessment, which will
							provide assurance that the service capability meets
							the organisation needs in terms of devices to be
							covered, the estimated volume, and policy
							requirements and preferences.
						</Text>
						<Text style={{ margin: 5 }}>
							A network design may be required to connect the{" "}
							{props.companyName} network infrastructure into HDM.
						</Text>
						<Text style={{ margin: 5 }}>
							Deployment of client-side agents.
						</Text>
						<Text style={{ margin: 5 }}>
							On-boarding of {props.prevSolution} into the Fujitsu
							systems, with customisation of the{" "}
							{props.companyName} service i.e. Helpdesk etc.
						</Text>
						<Text style={{ margin: 5 }}>
							Ongoing availability of live service, with regular
							monthly billing.
						</Text>
						<Image
							allowDangerousPaths={true}
							style={styles.image}
							src={DataImg}
						/>
						<Text style={{ fontWeight: 700, margin: 5 }}>
							HDM Start-up Charges:
						</Text>
						<Text style={{ marginLeft: 5 }}>
							HDM customer design ={" "}
							{toGBP.format(props.customerDesign)}
						</Text>
						<Text style={{ marginLeft: 5 }}>
							Network design & deploy ={" "}
							{toGBP.format(props.networkDesignDeploy)}
						</Text>
						<Text style={{ marginLeft: 5 }}>
							Client on-boarding ={" "}
							{toGBP.format(props.onboarding)}
						</Text>

						<Text style={{ fontWeight: 700, margin: 5 }}>
							Monthly HDM Charges:{" "}
						</Text>
						<Text style={{ marginLeft: 5 }}>
							Standard retention ({props.standardRetention} TB) ={" "}
							{toGBP.format(props.backupCost)}
						</Text>
						<Text style={{ marginLeft: 5 }}>
							Extended retention ({props.extendedRetention} TB) ={" "}
							{toGBP.format(props.extendedCost)}
						</Text>
						<Text style={{ marginLeft: 5 }}>
							Network connectivity ={" "}
							{toGBP.format(props.networkConnect)}
						</Text>

						<Text style={{ fontWeight: 700, margin: 5 }}>
							Total HDM Charges:
						</Text>
						<Text style={{ marginLeft: 5 }}>
							Total start-up charge =
							<Text style={{ textAlign: "right" }}>
								{" "}
								{toGBP.format(props.totalStartup)}
							</Text>
						</Text>
						<Text style={{ marginLeft: 5 }}>
							Total monthly charge =
							{toGBP.format(props.totalMonthly)}
						</Text>
						<Text style={{ marginLeft: 5 }}>
							Total over {props.contractLength} month(s) ={" "}
							{toGBP.format(props.totalContract)}
						</Text>
					</View>
				</View>
			</View>
			<View
				style={[
					styles.rowFlex,
					{
						border: "2pt",
						borderColor: "#A30B1A",
						minHeight: heightStyles.rowFour,
						maxHeight: heightStyles.rowFour,
						marginLeft: "2%",
						marginRight: "2%",
					},
				]}
			>
				<View style={[styles.signoffBanner, { height: "100%" }]}></View>
				<View style={styles.signoffStatement}>
					<Text
						style={[styles.rightColumnHeadings, { paddingLeft: 0 }]}
					>
						Why Now?
					</Text>
					<Text style={{ fontWeight: 700, margin: 5 }}>
						With data ever growing, and the introduction of data
						compliance regulations such as GDPR, the complexity of
						understanding, storing and protecting this vital asset
						is becoming increasingly challenging.
					</Text>
					<Text style={{ fontWeight: 700, margin: 5 }}>
						This challenge increases when a Hybrid approach across
						public cloud, on premise services, IaaS, PaaS, SaaS and
						mobile devices is considered commonplace.
					</Text>
					<Text
						style={{ fontWeight: 700, margin: 5, color: "#A30B1A" }}
					>
						Let Fujitsu’s Hybrid Data Management service help you
						manage these challenges by Searching, Controlling,
						Protecting and Regulating your data using industry
						leading technology.
					</Text>
				</View>
			</View>
			<View
				style={[
					styles.rowFlex,
					{
						minHeight: heightStyles.rowFive,
						maxHeight: heightStyles.rowFive,
					},
				]}
			>
				<View style={styles.copyright}>
					<Text>Copyright Fujitsu 2020. All rights reserved.</Text>
					<Text>
						This document is Fujitsu proprietary information, not
						for sale; reproduction; distribution or transfer.
					</Text>
				</View>
				<View style={styles.fujitsuMessage}>
					<Text>Creating tomorrow's world with you</Text>
				</View>
			</View>
		</Page>
	</Document>
);
