import styled from "@emotion/styled";
export const TotalCostDiv = styled.div`
	display: flex;
	justify-content: space-around;
	background-color: #ffe5dd;
	border-radius: 20px;
	margin: 1.2em;
	-webkit-box-shadow: 1px 2px 3px 4px #ccc;
	-moz-box-shadow: 1px 2px 3px 4px #ccc;
	box-shadow: 1px 2px 3px 4px #ccc;
`;

export const H1 = styled.h1`
	font-size: 1.3em;
`;
export const H2 = styled.h2`
	font-size: 1.2rem;
`;

export const Cost = styled.h2`
	color: #5c7029;
	font-size: 1.1em;
`;

export const ButtonGroup = styled.div`
	display: flex;
	justify-content: space-between;
`;
