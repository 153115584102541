import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AdminContext } from "../../context/AdminContext";

const Footer: React.FC = (): JSX.Element => {
	const { admin } = useContext(AdminContext);
	return (
		<div
			style={{
				width: "100%",
				backgroundColor: "#962127",
				height: "50px",
				display: "flex",
				justifyContent: "space-between",
				padding: "16px",
				fontWeight: "bold",
				color: "#DADADA",
			}}
		>
			<div>
				© Copyright 2020 Fujitsu | Fujitsu Restricted - Internal Use
				Only
			</div>
			<div style={{ display: "flex", justifyContent: "space-evenly" }}>
				<ul style={{ margin: "0px" }} id="links">
					<>
						{" "}
						<a
							style={{
								display: "inline",
								paddingLeft: "5px",
								paddingRight: "5px",
								textDecoration: "underline",
								color: "#DADADA",
							}}
							href={`${process.env.PUBLIC_URL}/user-manual-hdm.pdf`}
							download="HDM User Manual.pdf"
						>
							User Manual
						</a>{" "}
						|
					</>
					{admin === true ? (
						<>
							{" "}
							<a
								style={{
									display: "inline",
									paddingLeft: "5px",
									paddingRight: "5px",
									textDecoration: "underline",
									color: "#DADADA",
								}}
								href={`${process.env.PUBLIC_URL}/admin-manual-hdm.pdf`}
								download="HDM Admin Manual.pdf"
							>
								Admin Manual
							</a>{" "}
							|
						</>
					) : (
						<></>
					)}
					<a
						style={{
							display: "inline",
							paddingLeft: "5px",
							paddingRight: "5px",
							textDecoration: "underline",
							color: "#DADADA",
						}}
						href="https://portfolio.transformtogether.solutions/product/223/hybrid-data-management/5"
					>
						HDM Fit4Winning Assets
					</a>
					|
					<a
						style={{
							display: "inline",
							paddingLeft: "5px",
							paddingRight: "5px",
							textDecoration: "underline",
							color: "#DADADA",
						}}
						href="https://www.fujitsu.com/global/about/"
					>
						About
					</a>
					|
					<Link
						style={{
							display: "inline",
							paddingLeft: "5px",
							paddingRight: "5px",
							textDecoration: "underline",
							color: "#DADADA",
						}}
						to="/privacy"
					>
						Privacy
					</Link>
					|
					<a
						style={{
							display: "inline",
							paddingLeft: "5px",
							paddingRight: "5px",
							textDecoration: "underline",
							color: "#DADADA",
						}}
						href="https://www.fujitsu.com/global/about/resources/terms/index.html"
					>
						Terms of Use
					</a>
				</ul>
			</div>
		</div>
	);
};

export default Footer;
