import React, { useState, useEffect, useRef, MutableRefObject } from "react";
import { Button } from "primereact/button";
import { getDataMonthly } from "../../serviceWorker";
import ConfirmationPopUp from "../ConfirmationPopUp/ConfirmationPopUp";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { Option } from "../../types";
interface EditStandardProps {
	clearOptions: () => void;
}
const EditMonthly: React.FC<EditStandardProps> = ({
	clearOptions,
}): JSX.Element => {
	const costTypeRef = useRef() as MutableRefObject<HTMLInputElement>;
	const unitCostRef = useRef() as MutableRefObject<HTMLInputElement>;
	const [data, setData] = useState<Array<any>>([]);
	const [category, setCategory] = useState();
	const [costId, setCostId] = useState();
	const [open, setOpen] = React.useState(false);
	const [loadingPage, setLoadingPage] = useState(true);
	const [loadingRequest, setLoadingRequest] = useState<boolean>(true);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		getDataMonthly().then((response) => {
			setData(
				response.filter((option: Option) => {
					return option.Category !== "Model Type";
				})
			);
		});
		setLoadingPage(false);
	}, []);

	async function editCost() {
		handleClickOpen();
		try {
			await fetch(
				"https://ey6zu8r7jk.execute-api.eu-west-2.amazonaws.com/prod/getmonthly",
				{
					body: JSON.stringify({
						Name: costId,
						Category: category,
						Description: costTypeRef.current.value.trim(),
						UnitCost: unitCostRef.current.value.toString(),
					}),
					method: "POST",
				}
			);
		} catch (error) {
			console.log(error);
		}
		setLoadingRequest(false);
	}

	return !loadingPage ? (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				textAlign: "center",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignContent: "center",
					width: "20%",
					height: "100%",
				}}
			>
				{" "}
				<ConfirmationPopUp
					open={open}
					handleClose={handleClose}
					handleClickOpen={handleClickOpen}
					type="standard"
					loading={loadingRequest}
				/>
				<h1>Edit Monthly Costs</h1>
				<div>
					<label>Select Cost Type</label>
					<br />
					<select
						id="dropdown"
						style={{ width: "15vw" }}
						onChange={(e: any) => {
							const index = e.nativeEvent.target.selectedIndex;
							const label = e.nativeEvent.target[index].text;

							let filter = data.filter((option) => {
								return option.Description === label;
							});

							setCostId(filter[0].Name);
							setCategory(filter[0].Category);

							costTypeRef.current.value = label;
							unitCostRef.current.value = filter[0].UnitCost;
						}}
					>
						<option value=""></option>
						{data !== undefined ? (
							data.map((option: Option) => (
								<option key={option.Description}>
									{option.Description}
								</option>
							))
						) : (
							<option></option>
						)}
					</select>
				</div>
				<div
					style={{
						marginTop: 20,
						display: "flex",
						flexDirection: "column",
					}}
				>
					<div style={{ marginBottom: 20 }}>
						<label>New Cost Type Name</label>
						<br />
						<input
							ref={costTypeRef}
							id="cost-name"
							type="text"
							defaultValue={
								costTypeRef.current
									? costTypeRef.current.value
									: undefined
							}
							style={{ textAlign: "center", width: "15vw" }}
						></input>
					</div>
					<div>
						<label>New Unit Cost</label>
						<br />
						<input
							ref={unitCostRef}
							id="unit-cost"
							type="number"
							defaultValue={
								unitCostRef.current
									? unitCostRef.current.value
									: undefined
							}
							min={0}
							style={{ textAlign: "center", width: "15vw" }}
						></input>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							width: "100%",
						}}
					>
						<div
							style={{
								width: "15vw",
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<Button
								label="Back"
								className="p-button-raised"
								style={{
									marginTop: 50,
									width: 100,
									fontFamily: "FujitsuSans",
								}}
								onClick={clearOptions}
							/>
							<Button
								id="edit-monthly-button"
								label="Submit"
								disabled={
									unitCostRef.current
										? unitCostRef.current.value &&
										  costTypeRef.current.value
											? false
											: true
										: false
								}
								className="p-button-raised"
								style={{
									marginTop: 50,
									width: 100,
									fontFamily: "FujitsuSans",
								}}
								onClick={editCost}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	) : (
		<LoadingSpinner />
	);
};

export default EditMonthly;
