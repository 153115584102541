import React from "react";
import { Link } from "react-router-dom";

const PageNotFound: React.FC = (): JSX.Element => {
	return (
		<div>
			<h1>That page doesn't exist yet!</h1>
			<p>
				Please click <Link to="/">here</Link> to get back on track.
			</p>
		</div>
	);
};

export default PageNotFound;
