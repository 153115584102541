import React, { useState } from "react";
import { Link } from "react-router-dom";
import ItemSelection from "../../components/ItemSelection/ItemSelection";
import "primeflex/primeflex.css";
import "../../styling/App.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "../../styling/ButtonDemo.css";
import { Auth } from "aws-amplify";
import styled from "@emotion/styled";

export const H1 = styled.h1`
	background-color: #962127;
	border-top-left-radius: 13px;
	border-top-right-radius: 13px;
	padding: 0.3em;
	margin-top: -20px;
	color: #ffffff;
	margin-left: -20px;
	margin-right: -20px;
	text-align: center;
`;

const CustomerDiv = styled.div`
	-webkit-box-shadow: 1px 2px 3px 4px #ccc;
	-moz-box-shadow: 1px 2px 3px 4px #ccc;
	box-shadow: 1px 2px 3px 4px #ccc;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	background-color: #ffe5dd;
	padding: 20px;
	border-radius: 15px;
	margin-top: 10%;
	display: flex;
	flex-direction: column;
	margin-bottom: 5%;
`;

interface CustomerInfoContinue {
	pathname: string;
	company: string;
	solution: string;
}

async function signOut() {
	try {
		await Auth.signOut();
		sessionStorage.clear();
	} catch (error) {
		console.log("error signing out: ", error);
	}
}

const CustomerInfo: React.FC = (): JSX.Element => {
	const [company, setCompany] = useState(() => {
		const existingCompany = sessionStorage.getItem("companyName");
		return existingCompany ? existingCompany : "";
	});
	const [solution, setSolution] = useState(() => {
		const existingSolution = sessionStorage.getItem("prevSolution");
		return existingSolution ? existingSolution : "";
	});

	function saveToSession() {
		sessionStorage.setItem("companyName", company);
		sessionStorage.setItem("prevSolution", solution);
	}

	const isEmpty = (str: any) => !str.trim().length;
	const validateInput = (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
	) => {
		if (isEmpty(company) || isEmpty(solution)) {
			e.preventDefault();
			alert("Fields can't be empty");
		}
	};

	return (
		<div
			style={{
				width: "25%",
				height: "25%",
				marginRight: "auto",
				marginLeft: "auto",
			}}
		>
			<CustomerDiv>
				<H1>Customer Info</H1>

				<div className="p-fluid">
					<div className="p-field">
						<label
							style={{ fontSize: "1.1em" }}
							htmlFor="firstname1"
						>
							Customer name
						</label>
						<InputText
							style={{
								border: "2px solid black",
							}}
							value={company}
							id="firstname1"
							type="text"
							onChange={(
								e: React.FormEvent<HTMLInputElement>
							) => {
								setCompany(
									(e.target as HTMLInputElement).value
								);
							}}
						/>
					</div>

					<div className="p-field">
						<label style={{ fontSize: "1em" }} htmlFor="lastname1">
							Name of existing backup solution
						</label>
						<InputText
							style={{
								border: "2px solid black",
							}}
							value={solution}
							id="lastname1"
							type="text"
							onChange={(
								e: React.FormEvent<HTMLInputElement>
							) => {
								setSolution(
									(e.target as HTMLInputElement).value
								);
							}}
						/>
					</div>

					<div style={{ marginBottom: "1.5rem" }}>
						<label style={{ fontSize: "1em" }} htmlFor="fileUpload">
							Upload customer logo
						</label>
						<ItemSelection />
					</div>
				</div>
			</CustomerDiv>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div>
					<Button
						label="Sign Out"
						className="p-button-raised"
						onClick={signOut}
						style={{
							marginTop: 10,
							width: 100,
							fontFamily: "FujitsuSans",
						}}
					/>
				</div>
				<div>
					<Link
						to={
							{
								pathname: "/startup",
								company: company,
								solution: solution,
							} as CustomerInfoContinue
						}
						style={{ textDecoration: "none" }}
						onClick={(e) => validateInput(e)}
					>
						<Button
							disabled={isEmpty(company) || isEmpty(solution)}
							label="Continue"
							className="p-button-raised"
							onClick={saveToSession}
							style={{
								marginTop: 10,
								width: 100,
								fontFamily: "FujitsuSans",
							}}
						/>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default CustomerInfo;
