import { AxiosError } from "axios";
import { contactEmail } from "../globals";

export const handleNetworkErrors = (err: any): void => {
	if (err.response) {
		// AxiosError, 4xx or 5xx status code

		const error = err as AxiosError;
		console.log("Axios error: ", error.response);
		throw new Error(
			`Error ${error.code}: ${error.message}. If this happens again, please contact ${contactEmail}`
		);
	} else if (err.request) {
		// AxiosError, Issue sending request

		const error = err as AxiosError;
		console.log("Axios error: ", error.request);
		throw new Error(
			`Error sending request. ${error.message}. If this happens again, please contact ${contactEmail}`
		);
	} else {
		// Anything else
		console.log("Error: ", err);

		throw new Error(
			`There was an unexpected error retrieving data. Please inform ${contactEmail} if this happens again.`
		);
	}
};
