import React, { MutableRefObject, useEffect, useRef } from "react";
import { Action, MonthlyCostState, Threshold } from "../../types";

// User inputs values in TB - all calcs are done based on GB. Make sure always
// divide by 1000 when showing the user a value, or multiple by 1000 when user input value;
import {
	formatValue2Dp,
	formatValue3Dp,
	getMonthlyInitStateFromStorage,
	regexMatchDecimal,
} from "../../utils";
import { H2, InputMain } from "./styles";
import { GB_TO_TB_FACTOR, TB_TO_GB_FACTOR } from "../../globals";
import MonthlyCostInputTotalData from "../MonthlyCostInputTotalData/MonthlyCostInputTotalData";
import MonthlyCostInputExtendedData from "../MonthlyCostInputExtendedData/MonthlyCostInputExtendedData";
import MonthlyCostInputContractDuration from "../MonthlyCostInputContractDuration/MonthlyCostInputContractDuration";
import MonthlyCostInputMargin from "../MonthlyCostInputMargin/MonthlyCostInputMargin";

interface MonthlyCostInputProps {
	state: MonthlyCostState;
	dispatch: React.Dispatch<Action>;
	thresholds: Threshold[];
}

const MonthlyCostInput: React.FC<MonthlyCostInputProps> = ({
	state,
	dispatch,
}: MonthlyCostInputProps): JSX.Element => {
	const dataRef = useRef() as MutableRefObject<HTMLInputElement>;
	const extendedRef = useRef() as MutableRefObject<HTMLInputElement>;
	const durationRef = useRef() as MutableRefObject<HTMLInputElement>;
	const marginRef = useRef() as MutableRefObject<HTMLInputElement>;

	// Initialise state if in storage - needs to be here due to closure over Ref.current value I think.
	useEffect(() => {
		const getInitState = async () => {
			const {
				data,
				extended,
				duration,
				margin,
			} = getMonthlyInitStateFromStorage();

			console.log(
				"RESPONSE",
				dataRef,
				extendedRef,
				durationRef,
				marginRef
			);

			if (data && dataRef.current) {
				await dispatch({ type: "data", payload: parseFloat(data) });
				dataRef.current.value = formatValue3Dp(
					parseFloat(data) * GB_TO_TB_FACTOR
				).toString();
			}
			if (extended && extendedRef.current) {
				await dispatch({
					type: "extended",
					payload: parseFloat(extended),
				});
				extendedRef.current.value = formatValue3Dp(
					parseFloat(extended) * GB_TO_TB_FACTOR
				).toString();
			}
			if (duration && durationRef.current) {
				await dispatch({
					type: "duration",
					payload: parseInt(duration),
				});
				durationRef.current.value = duration;
			}
			if (margin && marginRef.current) {
				await dispatch({ type: "margin", payload: parseFloat(margin) });
				marginRef.current.value = margin;
			}
		};
		getInitState();
	}, [dispatch, dataRef, durationRef, extendedRef, marginRef]);

	// Here due to closure over e.target.value
	const formatInput = ({
		e,
		decimalPlaces,
	}: {
		e: React.ChangeEvent<HTMLInputElement>;
		decimalPlaces: number;
	}): void => {
		let value;
		if (e.target.value === "") return;
		if (decimalPlaces === 3) {
			value = formatValue3Dp(parseFloat(e.target.value));
		} else if (decimalPlaces === 2) {
			value = formatValue2Dp(parseFloat(e.target.value));
		} else if (decimalPlaces === 0) {
			value = parseInt(e.target.value);
		} else {
			alert("Invalid input detected.");
			return;
		}
		e.target.value = value.toString();
	};

	const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
		if (!e.target.value) {
			e.target.value = e.target.defaultValue;
		}
	};

	const handleOnChangeData = ({
		e,
		decimalToFormatTo,
		dispatchType,
	}: {
		e: React.ChangeEvent<HTMLInputElement>;
		decimalToFormatTo: number;
		dispatchType: string;
	}) => {
		if (regexMatchDecimal(e.target.value)) return;
		formatInput({ e, decimalPlaces: decimalToFormatTo });

		if (e.target.value === "") {
			dispatch({
				type: dispatchType,
				payload: 0,
			});
		} else {
			dispatch({
				type: dispatchType,
				payload:
					formatValue3Dp(parseFloat(e.target.value)) *
					TB_TO_GB_FACTOR,
			});
		}
	};

	return (
		<InputMain>
			<H2>Input</H2>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					height: "90%",
				}}
			>
				<MonthlyCostInputTotalData
					ref={dataRef}
					handleBlur={handleBlur}
					handleOnChangeData={handleOnChangeData}
				/>
				<MonthlyCostInputExtendedData
					ref={extendedRef}
					dataRequested={state.dataRequested}
					handleBlur={handleBlur}
					handleOnChangeData={handleOnChangeData}
				/>

				<MonthlyCostInputContractDuration
					handleBlur={handleBlur}
					dispatch={dispatch}
					ref={durationRef}
					formatInput={formatInput}
				/>

				<MonthlyCostInputMargin
					handleBlur={handleBlur}
					dispatch={dispatch}
					formatInput={formatInput}
					ref={marginRef}
				/>
			</div>
		</InputMain>
	);
};

export default MonthlyCostInput;
