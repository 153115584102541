import React, { useContext } from "react";
import { Auth } from "aws-amplify";
import {
	AmplifyAuthenticator,
	AmplifySignUp,
	AmplifySignIn,
} from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { I18n } from "aws-amplify";
import "../../styling/ButtonDemo.css";
import "primeflex/primeflex.css";
import "../../styling/App.css";

import { UserContext } from "../../context/UserContext";
import { AuthContext } from "../../context/AuthContext";
import { AdminContext } from "../../context/AdminContext";
import { useHistory } from "react-router-dom";

// export default App;

const AuthStateApp = ({
	children,
}: {
	children: React.ReactNode;
}): JSX.Element => {
	const { user, setUser } = useContext(UserContext);
	const { authState, setAuthState } = useContext(AuthContext);
	const { setAdmin } = useContext(AdminContext);
	const history = useHistory();

	React.useEffect(() => {
		if (setUser && setAuthState && setAdmin) {
			if (authState === undefined) {
				Auth.currentAuthenticatedUser()
					.then((authData) => {
						setAuthState(AuthState.SignedIn);
						setUser(authData);
					})
					.catch(
						(err) => console.log(err) // Not Authenticated
					);
			}
			// eslint-disable-next-line
			return onAuthUIStateChange((nextAuthState, authData: any) => {
				setAuthState(nextAuthState);
				setUser(authData);

				if (authData) {
					if (authData.setSignInUserSession) {
						const groups = authData!.signInUserSession.accessToken
							.payload["cognito:groups"];
						if (groups.includes("Admin")) {
							setAdmin(true);
						} else {
							setAdmin(false);
						}

						// // Redirect to main page, but causes erros with retaining state across refreshes
					}
				} else {
					history.push("/");
					setAdmin(false);
				}
			});
		}
	}, [setAdmin, setAuthState, setUser, authState, history]);

	return authState === AuthState.SignedIn && user ? (
		<div>{children}</div>
	) : (
		<div
			style={{
				height: "85.8vh",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<AmplifyAuthenticator usernameAlias="email">
				<AmplifySignIn
					headerText="Sign In to HDM Portal"
					slot="sign-in"
					usernameAlias="email"
				/>
				<AmplifySignUp
					slot="sign-up"
					usernameAlias="email"
					formFields={[
						{
							type: "name",
							label: "Name *",
							placeholder: "e.g. Joe Bloggs",
							required: true,
						},
						{
							type: "email",
							label: "Email *",
							placeholder: "e.g. joe.bloggs@fujitsu.com",
							required: true,
						},
						{
							type: "password",
							label: "Password *",
							placeholder: "e.g. Password123!",
							required: true,
						},
					]}
				/>
				{/* <AmplifyConfirmSignUp usernameAlias="email" /> */}
			</AmplifyAuthenticator>
			<div style={{ justifyContent: "center", textAlign: "center" }}>
				<h3>
					Please note that although the figures obtained within this
					provocation are accurate, and will always align with the HDM
					on-boarding cost model, they should not be used for
					presentation to the customer before engaging with the HDM
					Service Delivery team and Product Manager.
				</h3>
			</div>
		</div>
	);
};

const authScreenLabels = {
	en: {
		"Sign Up": "Create new account",
		"Sign Up Account": "Create a new account",
		"Sign In": "Sign In",
	},
};

I18n.setLanguage("en");
I18n.putVocabularies(authScreenLabels);

export default AuthStateApp;
