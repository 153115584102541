import { useEffect, useState } from "react";
import { DesiredData, StartUpApiResponse } from "../types";
import axios from "axios";
import { handleNetworkErrors } from "../utils/handleNetworkErrors";

export const useInitStartupData = (
	desiredCategories: string[],
	setThrowsError: React.Dispatch<React.SetStateAction<undefined>>
): DesiredData | undefined => {
	const [desiredData, setDesiredData] = useState<DesiredData>();
	useEffect(() => {
		const getData = async () => {
			const dataTemplate: DesiredData = {};
			try {
				const { data }: { data: StartUpApiResponse } = await axios.get(
					"https://ey6zu8r7jk.execute-api.eu-west-2.amazonaws.com/prod/getrefactor"
				);

				if (!data.response) {
					const errMsg = data.message
						? data.message
						: "Something went wrong";
					throw Error(errMsg);
				}

				const items = data.response.Items;
				desiredCategories.forEach((category) => {
					const optionsArr = items.filter(
						(selection) => selection.Category === category
					);
					dataTemplate[category] = optionsArr;
				});
				setDesiredData(dataTemplate);
			} catch (err: unknown) {
				handleNetworkErrors(err);
			}
		};
		getData().catch((err: Error) => {
			console.log(err);
			setThrowsError(() => {
				throw err;
			});
		});
	}, [desiredCategories, setThrowsError]);

	return desiredData;
};
