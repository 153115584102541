import styled from "@emotion/styled";

export const CategoryGrid = styled.div`
	display: grid;
	grid-template-columns: 20% 40% 20% 20%;
	justify-content: center;
	-webkit-box-shadow: 1px 2px 3px 4px #ccc;
	-moz-box-shadow: 1px 2px 3px 4px #ccc;
	box-shadow: 1px 2px 3px 4px #ccc;
	margin: 0.8em;
	border-radius: 20px;
	align-content: center;
	align-items: center;
	justify-items: center;
	background-color: #ffe5dd;
`;

export const CategoryNameDiv = styled.div`
	grid-column: 1/2;
	border-radius: 18px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 5px;
	place-self: stretch stretch;
	/* background-color: #a30b1a; */
	background-color: #962127;
	color: #ffffff;
`;

export const CategoryTitle = styled.h3`
	margin: 0em;
	font-size: 1em;
`;

export const CostDiv = styled.div`
	grid-column: 4/5;
	grid-row: auto;
	justify-self: center;
	align-self: center;
`;
export const Cost = styled.h3`
	margin: 0;
	color: #5c7029;
	font-size: 1.05rem;
`;
