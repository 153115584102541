import React, { useState, useEffect } from "react";
import { getModelType } from "../../serviceWorker";
import { InputSwitch } from "primereact/inputswitch";

const ModelSwitch: React.FC = (): JSX.Element => {
	const [modelSwitch, setmodelSwitch] = useState<boolean>();
	const tier = true;
	const tax = false;

	useEffect(() => {
		getModelType().then((response) => {
			if (response[0].Description === "tier") {
				setmodelSwitch(tier);
			} else if (response[0].Description === "tax") {
				setmodelSwitch(tax);
			}
		});
	}, [tax, tier]);

	async function changeCostModel() {
		if (modelSwitch === tier) {
			setmodelSwitch(tax);
			try {
				await fetch(
					"https://ey6zu8r7jk.execute-api.eu-west-2.amazonaws.com/prod/editmodel",
					{
						body: JSON.stringify({
							Name: "model",
							Category: "Model Type",
							Description: "tax",
						}),
						method: "POST",
					}
				);
			} catch (error) {
				console.log(error);
			}
		} else if (modelSwitch === tax) {
			setmodelSwitch(tier);
			try {
				await fetch(
					"https://ey6zu8r7jk.execute-api.eu-west-2.amazonaws.com/prod/editmodel",
					{
						body: JSON.stringify({
							Name: "model",
							Category: "Model Type",
							Description: "tier",
						}),
						method: "POST",
					}
				);
			} catch (error) {
				console.log(error);
			}
		}
	}
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
			}}
		>
			<p>Tax Model</p>
			<InputSwitch
				id="toggle-button"
				style={{ margin: 10 }}
				checked={modelSwitch === tier}
				onChange={() => {
					changeCostModel();
				}}
			/>
			<p>Tiered Model</p>
		</div>
	);
};

export default ModelSwitch;
