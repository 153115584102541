import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactTooltip from "react-tooltip";
import { GB_TO_TB_FACTOR } from "../../globals";
import { InputDiv, InputLabel, NumberInput } from "../MonthlyCostInput/styles";

interface MonthlyCostInputTotalDataProps {
	handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
	handleOnChangeData: ({
		e,
		decimalToFormatTo,
		dispatchType,
	}: {
		e: React.ChangeEvent<HTMLInputElement>;
		decimalToFormatTo: number;
		dispatchType: string;
	}) => void;
}

const MonthlyCostInputTotalData = React.forwardRef<
	HTMLInputElement,
	MonthlyCostInputTotalDataProps
>(
	(props, ref): JSX.Element => {
		return (
			<InputDiv>
				<InputLabel htmlFor="dataRequested">
					Total data retained for 35 days standard retention
					(TB)&nbsp;
					<FontAwesomeIcon
						data-tip
						data-for="standardRetention"
						icon={faQuestionCircle}
					/>
				</InputLabel>
				<ReactTooltip
					id="standardRetention"
					effect="solid"
					place="right"
				>
					Enter the amount of data to be backed up and retained for 35
					days (please note that all data to be protected must be
					specified here)
				</ReactTooltip>
				<NumberInput
					style={{
						fontSize: "1.2em",
						textAlign: "center",
						width: "40%",
					}}
					onFocus={(e) => e.target.select()}
					id="dataRequested"
					step={GB_TO_TB_FACTOR}
					ref={ref}
					type="number"
					defaultValue={0}
					name="dataRequested"
					min={0}
					onBlur={(e) => props.handleBlur(e)}
					onChange={(e) => {
						props.handleOnChangeData({
							e,
							decimalToFormatTo: 3,
							dispatchType: "data",
						});
					}}
				/>
			</InputDiv>
		);
	}
);

export default MonthlyCostInputTotalData;
