import React from "react";
import { Card } from "primereact/card";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";

interface EditCostOptionsProps {
	handleEditStartUp: () => void;
	handleEditMonthly: () => void;
	clearOptions: () => void;
}

const EditCostOptions: React.FC<EditCostOptionsProps> = ({
	handleEditStartUp,
	handleEditMonthly,
	clearOptions,
}): JSX.Element => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignContent: "center",
				width: "100%",
				height: "100%",
			}}
		>
			{" "}
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					textAlign: "center",
					marginTop: 115,
				}}
			>
				<Link
					to="#"
					style={{ textDecoration: "none" }}
					onClick={handleEditStartUp}
					id="edit-startup-card"
				>
					<Card
						style={{
							backgroundColor: "#A30B1A",
							margin: 40,
							width: 250,
							height: 150,
							color: "white",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						}}
					>
						Edit Start Up Cost
					</Card>
				</Link>
				<Link
					to="#"
					style={{ textDecoration: "none" }}
					onClick={handleEditMonthly}
					id="edit-monthly-card"
				>
					<Card
						style={{
							backgroundColor: "#A30B1A",
							margin: 40,
							width: 250,
							height: 150,
							color: "white",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						}}
					>
						Edit Monthly Cost
					</Card>
				</Link>
			</div>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<Button
					label="Back"
					className="p-button-raised"
					style={{
						marginTop: 50,
						width: 100,
						fontFamily: "FujitsuSans",
					}}
					onClick={clearOptions}
				></Button>
			</div>
		</div>
	);
};

export default EditCostOptions;
