import React from "react";
import { Action } from "../../types";
import { InputDiv, InputLabel, NumberInput } from "../MonthlyCostInput/styles";

interface MonthlyCostInputContractDurationProps {
	handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
	formatInput: ({
		e,
		decimalPlaces,
	}: {
		e: React.ChangeEvent<HTMLInputElement>;
		decimalPlaces: number;
	}) => void;
	dispatch: React.Dispatch<Action>;
}

const MonthlyCostInputContractDuration = React.forwardRef<
	HTMLInputElement,
	MonthlyCostInputContractDurationProps
>(
	(props, ref): JSX.Element => {
		return (
			<InputDiv>
				<InputLabel htmlFor="duration">
					Contract Duration (months)
				</InputLabel>
				<NumberInput
					id="duration"
					ref={ref}
					type="number"
					name="duration"
					defaultValue={1}
					min={0}
					onFocus={(e) => e.target.select()}
					onBlur={(e) => props.handleBlur(e)}
					onChange={(e) => {
						props.formatInput({ e, decimalPlaces: 0 });

						if (e.target.value === "") {
							props.dispatch({
								type: "duration",
								payload: 0,
							});
						} else {
							props.dispatch({
								type: "duration",
								payload: parseInt(e.target.value),
							});
						}
					}}
				/>
			</InputDiv>
		);
	}
);

export default MonthlyCostInputContractDuration;
