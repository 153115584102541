import { formatValue2Dp } from "./formatValue";
import { MonthlyCostState, Threshold } from "../types";

// Mutualy exclusive of total data - therefore one could qualify for the discounted rate on all GB of standard retention,
// but will only qualify for the reduced cost of the extended data they get.

export const calcualteExtendedCostTierModel = ({
	allThresholds,
	state,
	threshold,
}: {
	allThresholds: Threshold[];
	state: MonthlyCostState;
	threshold: Threshold;
}): number => {
	// Highest threshold first in array
	const reverseSortedThresholds = allThresholds.sort(
		(a, b) => b.threshold - a.threshold
	);

	// Highest threshold where extended data > threshold
	const highestThresholdOvercome = reverseSortedThresholds.filter(
		(threshold) => state.extendedData >= threshold.threshold
	)[0];
	let price = 0;

	// This might be an issue with other ones, however here if someone manages to get extended retention input to be undefined
	// And that gets saved into LS, can break the site for them until they clear LS.
	// Might need some check in place to prevent this.
	if (highestThresholdOvercome === undefined) return 0;

	// Will only return a price for the threshold that user is currently in WRT extended data
	if (threshold.threshold === highestThresholdOvercome.threshold) {
		price = state.extendedData * threshold.extendedAdditional;
	}
	return formatValue2Dp(price);
};
