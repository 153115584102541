import { Action, MonthlyCostState } from "../types";
import { formatValue2Dp } from "../utils/formatValue";
import { calculateNewDataCost } from "../utils/calculateNewDataCost";
import { calculateAdditionalCost } from "../utils/calculateAdditionalCost";

// 5 possible tiers
export const monthlyInitialState: MonthlyCostState = {
	dataRequested: 0,
	extendedData: 0,
	contractDuration: 1,
	totalMonthlyCost: 0,
	monthlyThresholdCostTotals: {
		"1": 0,
		"2": 0,
		"3": 0,
		"4": 0,
		"5": 0,
	},
	additionalMonthlyCosts: [],
	margin: 0,
};

export const monthlyReducer = (
	state: MonthlyCostState,
	action: Action
): MonthlyCostState => {
	switch (action.type) {
		case "data":
			// This statement is here to fix weird bug where will hang at 1GB price for total cost, then will reset when adding GB again
			// Remove this is there becomes another source of cost other than data (e.g ports may mess this up a little).
			if (action.payload === 0) {
				const {
					newAdditionalCost,
					newAdditionalCosts,
				} = calculateAdditionalCost(state, action);
				sessionStorage.setItem(
					"additionalCosts",
					JSON.stringify(newAdditionalCosts)
				);

				return {
					...state,
					dataRequested: action.payload,
					monthlyThresholdCostTotals: {
						"1": 0,
						"2": 0,
						"3": 0,
						"4": 0,
						"5": 0,
					},
					additionalMonthlyCosts: newAdditionalCosts,
					totalMonthlyCost: newAdditionalCost,
				};
			}
			return { ...state, dataRequested: action.payload };
		case "extended":
			return { ...state, extendedData: action.payload };
		case "duration":
			return { ...state, contractDuration: action.payload };
		case "dataCost": {
			const {
				newTotalDataCost,
				newMonthlyThresholdCosts,
			} = calculateNewDataCost(state, action);
			const additionalCosts = state.additionalMonthlyCosts
				.map((item) => item.cost)
				.reduce((acc, curr) => acc + curr, 0);
			const newTotal = formatValue2Dp(newTotalDataCost + additionalCosts);

			return {
				...state,
				monthlyThresholdCostTotals: newMonthlyThresholdCosts,
				totalMonthlyCost: newTotal,
			};
		}
		case "additionalCost": {
			const {
				newAdditionalCost,
				newAdditionalCosts,
			} = calculateAdditionalCost(state, action);
			sessionStorage.setItem(
				"additionalCosts",
				JSON.stringify(newAdditionalCosts)
			);

			const dataCost: number = Object.keys(
				state.monthlyThresholdCostTotals
			)
				.map((item) => (state.monthlyThresholdCostTotals as any)[item])
				.reduce((acc, curr) => acc + curr, 0);

			const newTotal = formatValue2Dp(newAdditionalCost + dataCost);

			console.log("MY NEW TTOAL", newTotal);
			console.log("MY NEW costs", newAdditionalCosts);

			return {
				...state,
				additionalMonthlyCosts: newAdditionalCosts,
				totalMonthlyCost: newTotal,
			};
		}
		case "margin":
			return { ...state, margin: action.payload };

		default:
			return state;
	}
};
