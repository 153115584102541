import styled from "@emotion/styled";
import { Button } from "primereact/button";
import React from "react";
import { useHistory } from "react-router-dom";

const PrivacyContainer = styled.div`
	margin: 2rem auto;
	width: 60rem;
	line-height: 1.5;
	word-spacing: 2px;

	& li {
		margin: 10px 0px;
	}
`;

const Privacy: React.FC = () => {
	const history = useHistory();
	return (
		<div>
			<PrivacyContainer>
				<Button onClick={() => history.push("/")}>Back</Button>
				<h1>Privacy policy</h1>
				<section>
					<h2>Fujitsu and data privacy</h2>
					<p>
						Fujitsu Limited and its subsidiaries ("Fujitsu") are
						committed to protecting and respecting your privacy.
					</p>
					<p>
						This privacy policy sets out the basis on which we will
						process any personal information that we may collect
						about you as a visitor to our website or premises or a
						contact at one of our customers or potential customers,
						suppliers or potential suppliers or other business
						partners or in any other cases where we specifically
						state that this policy will apply.
					</p>
					<p>
						This privacy policy also sets out how we protect your
						privacy and your rights in respect of our use of your
						personal information.
					</p>
					<p>
						Fujitsu may have a privacy policy or statement specific
						to particular local laws, products or services in which
						case such policy or statement shall supersede or
						supplement this privacy policy.
					</p>
				</section>
				<section>
					<h2>What personal information do we collect from you?</h2>
					<p>
						We may collect and process the following personal
						information about you:
					</p>
					<ul>
						<li>
							Personal information that you give us: This is
							information about you that you give to us by filling
							in forms on our website (or other forms that we ask
							you to complete), giving us a business card (or
							similar) or corresponding with us by telephone,
							post, email or otherwise. It may include, for
							example, your name, address, email address and
							telephone number; information about your business
							relationship with Fujitsu; and information about
							your professional role, background and interests.
						</li>
						<li>
							Personal information that our website and other
							systems collect about you:
						</li>
						<ul>
							<li>
								If you visit our website it will automatically
								collect some information about you and your
								visit, including the Internet protocol (IP)
								address used to connect your device to the
								Internet and some other information such as the
								pages on our site that you visit. This is used
								to monitor the performance of the website and
								improve the experience of visitors to the
								website. Our website may also download "cookies"
								to your device – this is described in our
								separate cookie policy. As described further in
								the cookie policy, you can change the way in
								which we use cookies by changing your cookie
								preferences.
							</li>
							<li>
								If you interact with us through social media
								services, we may be able to access certain
								information about such interaction, such as
								whether you have interacted with us through
								multiple social networks. We make reasonable
								efforts to ensure that the social media
								providers have permission from you to allow us
								to access certain information about you. Please
								note that we are not responsible for the manner
								in which social media service providers handle
								your personal information that they may collect
								from you, as this is their responsibility. For
								instance, our website may use plugins of social
								media networks such as Twitter, Facebook,
								Linkedin, and Google+. If you use one of these
								plugins, these plugins are able to establish a
								direct connection between your browser and the
								sites of the respective social media networks.
								As this transfer takes place directly between
								your browser and the respective network, Fujitsu
								does not have any access, knowledge or control
								over any data sent or the use of this data
								within the respective social media networks. If
								you make use of a social media network and make
								content available to such networks, this is not
								covered by this privacy policy. Instead, the
								terms of use and privacy policies of the
								respective operators of those social media
								networks shall apply to any such content. We
								encourage you to read the privacy policies and
								terms and conditions of your social media
								service providers to understand how they handle
								your personal information.
							</li>
							<li>
								Our website may contain links to websites of
								third parties who are not affiliated to Fujitsu.
								If you access such third party websites by
								clicking on such links, we are not responsible
								for the way in which such third parties process
								your personal information that they collect.
							</li>
							<li>
								We may make web-based platforms available to our
								partners and suppliers and such platforms may
								collect your personal information if you use
								such platforms. This privacy policy applies to
								such personal information collected during the
								registration process and/or the use of such
								platforms.
							</li>
							<li>
								If you exchange emails, telephone conversations
								or other electronic communications with our
								employees and other staff members, our
								information technology systems may record
								details of those conversations, sometimes
								including their content.
							</li>
							<li>
								Some of our premises have closed circuit TV
								systems and other security and access management
								systems which may record you and certain
								information about your visit if you visit our
								premises, for security and safety purposes.
							</li>
						</ul>
						<li>
							Other information: We may also collect some
							information from other sources. For example:
						</li>
						<ul>
							<li>
								If we have a business relationship with the
								organisation that you represent, your colleagues
								or other business contacts may give us
								information about you such as your contact
								details or details of your role in the
								relationship.{" "}
							</li>
							<li>
								We sometimes collect information from third
								party data providers or publicly available
								sources for anti-money-laundering, export
								control, credit rating background checking and
								similar purposes, and to protect our business
								and comply with our legal and regulatory
								obligations.
							</li>
						</ul>
					</ul>
				</section>
				<section>
					<h2>How will we use your personal information?</h2>
					<p>
						We may collect, store and use your personal information
						for the following purposes:
					</p>
					<ul>
						<li>
							to operate, manage, develop and promote our business
							(including our products and services) and, in
							particular, our relationship with the organisation
							you represent (if any) and related transactions
							including, for example:
						</li>
						<ul>
							<li>
								marketing purposes (when we have either gathered
								prior opt-in consent and/or have a legitimate
								interest to send you communications which we
								believe to be relevant and of use to you);
							</li>
							<li>warranty processes;</li>
							<li>product compliance processes;</li>
							<li>
								accounting and billing / payment purposes
								(including to offer financing solutions to
								customers, together with our finance partners);
							</li>
							<li>
								to operate, administer and improve our website
								and premises and other aspects of the way in
								which we conduct our operations;
							</li>
							<li>
								to offer you the services of our online shops
								based on the terms and conditions of the
								dedicated web-shop;
							</li>
							<li>
								to provide you with services or information that
								you may have requested;
							</li>
							<li>
								to keep you informed and updated on relevant
								products or services you may be interested in;
							</li>
							<li>
								to enable you to take part in our online
								assessments and surveys;
							</li>
							<li>
								to manage and maintain the relationship with
								investors; and
							</li>
							<li>to operate recruiting activities;</li>
						</ul>
						<li>
							to protect our business from fraud,
							money-laundering, breach of confidence, theft of
							proprietary materials and other financial or
							business crimes;
						</li>
						<li>
							to comply with our legal and regulatory obligations
							and bring and defend legal claims and assert legal
							rights; and
						</li>
						<li>
							if the purpose is directly connected with an
							assigned purpose previously made known to you.
						</li>
					</ul>
					<p>
						We may from time to time review your information held in
						our systems – including the contents of and other
						information related to your email and other
						communications with us – for compliance and
						business-protection purposes as described above. This
						may include reviews for the purposes of disclosure of
						information relevant to litigation and/or reviews of
						records relevant to internal or external regulatory or
						criminal investigations. To the extent permitted by
						applicable law, these reviews will be conducted in a
						reasonable and proportionate way and approved at an
						appropriate level of management. They may ultimately
						involve disclosure of your information to governmental
						agencies and litigation counterparties as described
						below. Your emails and other communications may also
						occasionally be accessed by persons other than the
						member of staff with whom they are exchanged for
						ordinary business management purposes (for example,
						where necessary when a staff member is out of the office
						or has left Fujitsu).
					</p>
					<p>
						We will only process your personal information as
						necessary so that we can pursue the purposes described
						above and where we have a legal basis for such
						processing. Where our lawful basis for processing is
						that such processing is necessary to pursue our
						legitimate interests, we will only process your personal
						information where we have concluded that our processing
						does not prejudice you or your privacy in a way that
						would override our legitimate interest. In exceptional
						circumstances we may also be required by law to disclose
						or otherwise process your personal information.{" "}
					</p>
				</section>
				<section>
					<h2>
						Disclosure and international transfer of your personal
						information
					</h2>
					<p>
						We may disclose your personal information, where
						reasonably necessary for the various purposes set out
						above:
					</p>
					<ul>
						<li>
							to the other members of the Fujitsu group of
							companies;
						</li>
						<li>
							to your colleagues within the organisation that you
							represent;
						</li>
						<li>
							to service providers who host our web services or
							other information technology systems or otherwise
							hold or process your information on our behalf,
							under conditions of confidentiality and security
							required by law;
						</li>
						<li>
							business partners, channel partners, service
							partner, agents, suppliers and sub-contractors for
							the performance of any contract we enter into with
							them or you;
						</li>
						<li>
							to a person who takes over our business and assets,
							or relevant parts of them; or
						</li>
						<li>in exceptional circumstances:</li>
						<ul>
							<li>
								to competent regulatory, prosecuting and other
								governmental agencies, or litigation
								counterparties, in any country or territory; or
							</li>
							<li>where we are required by law to disclose.</li>
						</ul>
					</ul>
					<p>
						These disclosures may involve transferring your personal
						information overseas. If you are dealing with us within
						the European Economic Area, you should be aware that
						this may include transfers to countries outside the
						European Economic Area, which have not been determined
						by the European Commission to have an adequate level of
						data protection. In those cases, where we transfer your
						personal information to other members of the Fujitsu
						group or our service providers, we will ensure that our
						arrangements with them are governed by relevant legal
						mechanisms and safeguards including data transfer
						agreements, designed to ensure that your personal
						information is protected, on terms approved for this
						purpose by the European Commission.
					</p>
				</section>
				<section>
					<h2>How long do we keep your personal information?</h2>
					<p>
						We will delete your personal information when we no
						longer need such personal information, for instance
						where:
					</p>
					<ol type="i">
						<li>
							it is no longer necessary for us to retain your
							personal information to fulfil the purposes for
							which we had collected it;
						</li>
						<li>
							we believe that your personal information that we
							hold is inaccurate; or
						</li>
						<li>
							in certain cases where you have informed us that you
							no longer consent to our processing of your personal
							information.
						</li>
					</ol>
					<p>Sometimes, however:</p>
					<ol type="a">
						<li>
							there are legal or regulatory requirements which may
							require us to retain your personal information for a
							specified period, and in such cases we will retain
							your personal information for such specified period;
							and
						</li>
						<li>
							we may need to retain your personal information for
							certain longer periods for product liability
							purposes or in relation to legal disputes, and in
							such cases we will retain it for such longer periods
							to the extent required.
						</li>
					</ol>
					<p>
						Note that we may retain some limited information about
						you even when we know that you have left the
						organisation that you represent, so that we can maintain
						a continuous relationship with you if and when we are in
						contact with you again, representing a different
						organisation.
					</p>
				</section>
				<section>
					<h2>What are your rights?</h2>
					<p>
						You have the following rights (subject to applicable
						local laws) in relation to the personal information that
						we hold about you:
					</p>
					<ul>
						<li>
							to access your personal information, and some
							related information, under relevant data protection
							law;
						</li>
						<li>
							to require any inaccurate personal information to be
							corrected or deleted;
						</li>
						<li>
							to object to our use of your personal information
							for direct marketing purposes at any time and you
							may have the right to object to our processing of
							some or all of your personal information (and
							require them to be deleted) in some other
							circumstances;
						</li>
						<li>
							to require us to delete your personal information in
							certain circumstances;
						</li>
						<li>
							to require us to restrict or block the processing of
							your personal information in certain circumstances
							(when processing is restricted, we can still store
							your personal information, but may not use it
							further);
						</li>
						<li>
							to obtain from us your personal information, in a
							structured, commonly used and machine-readable
							format in certain circumstances. Further, you may
							have the right to require us to transmit your
							personal information directly to another person (for
							instance a new provider) where it is technically
							feasible to do so; and
						</li>
						<li>
							where we are processing your personal information
							based on your consent to such processing, to
							withdraw your consent at any time.
						</li>
					</ul>
					<p>
						Further information and advice about your rights can be
						obtained from the data protection regulator in your
						country.
					</p>
					<p>
						If you wish to exercise any of these rights (subject to
						applicable local laws), or have complaints about our
						processing of your personal information, please email{" "}
						<span style={{ color: "red" }}>
							EMEIA_DPOffice@ts.fujitsu.com
						</span>
						. You can also lodge a complaint about our processing of
						your personal information with the body regulating data
						protection in your country.
					</p>
				</section>
				<section>
					<h2>Sensitive Information</h2>
					<p>
						We request you not send to or share with us any
						sensitive personal information (e.g. information related
						to racial or ethnic origin, political opinions, religion
						or other beliefs, health, genetic, or biometric data,
						criminal background or trade union membership).
					</p>
				</section>
				<section>
					<h2>Personal Information of Children</h2>
					<p>
						Fujitsu respects the privacy of children and does not
						knowingly collect, use or disclose personal information
						of children under 16 years of age without verifiable
						consent from a parent or guardian.
					</p>
					<p>
						If Fujitsu becomes aware that the information submitted
						to or collected by Fujitsu is personal information of
						children without first receiving verifiable consent from
						a parent or guardian, Fujitsu will take prompt steps to
						delete such personal information.{" "}
					</p>
					<p>
						If a parent or guardian wishes to raise a concern
						regarding personal information pertaining to a child,
						please email our Data Protection Office at{" "}
						<span style={{ color: "red" }}>
							EMEIA_DPOffice@ts.fujitsu.com
						</span>
						.
					</p>
				</section>
				<section>
					<h2>Automated Decision Making</h2>
					<p>
						We may use technology which tracks your use of our
						website and your interactions with us and which helps us
						to build a profile of your preferred products and
						information requests. If we do this, what this means for
						you is that you are more likely to receive offers and
						information that are tailored to your specific
						preferences, based on your previous profile activity.
					</p>
					<p>
						If we use automated decision making in respect of any
						specific service or product and such automated decision
						making produces legal effects concerning data subjects
						or similarly significantly affects them, we will provide
						information required by applicable laws in respect of
						such automated decision making.
					</p>
				</section>
				<section>
					<h2>Contact us</h2>
					<p>
						If you have any questions, comments and requests
						regarding this privacy statement or our processing of
						personal information, please send an email to{" "}
						<span style={{ color: "red" }}>
							EMEIA_DPOffice@ts.fujitsu.com
						</span>
						.
					</p>
				</section>
				<section>
					<h2>Changes to this policy</h2>
					<p>
						This privacy policy is valid from the time of its
						publication on the Fujitsu websites, and all previous
						versions of this privacy policy become invalid as of
						such time. Any changes we make to this privacy statement
						in the future will be posted to our website and also
						available if you{" "}
						<span>
							<a href="https://www.fujitsu.com/global/contact/">
								Contact us
							</a>
						</span>
						. Please check back frequently to see any changes.
					</p>
				</section>
				<p>Last updated: 21 May 2018</p>
			</PrivacyContainer>
		</div>
	);
};

export default Privacy;
