import axios from "axios";
import { Threshold } from "../types";
import { getDiscounts } from "../serviceWorker";

export const SortDbThreshold = async (): Promise<any> => {
	var discounts: Threshold[] = [];
	var isAscending: boolean = true;

	await getDiscounts().then((response) => {
		discounts = response;
	});

	const checkAscending = () => {
		const sortByTier = discounts.sort((a, b) => (a.tier > b.tier ? 1 : -1));

		for (let i = 0; i < sortByTier?.length - 1; i++) {
			if (sortByTier[i]?.threshold < sortByTier[i + 1]?.threshold) {
				isAscending = true;
			} else if (
				sortByTier[i]?.threshold > sortByTier[i + 1]?.threshold
			) {
				return (isAscending = false);
			}
		}
	};

	const sortDbThreshold = async () => {
		const ascending = discounts.sort((a, b) =>
			a.threshold > b.threshold ? 1 : -1
		);
		const body = [];
		for (let i = 0; i < ascending.length; i++) {
			body[i] = {
				tier: (i + 1).toString(),
				threshold: ascending[i].threshold.toString(),
				basePrice: ascending[i].basePrice.toString(),
				extendedAdditional: ascending[i].extendedAdditional.toString(),
			};
		}

		for (let i = 0; i < discounts.length; i++) {
			await axios
				.delete(
					`https://ey6zu8r7jk.execute-api.eu-west-2.amazonaws.com/prod/delete?tier=${discounts[i].tier}`
				)
				.then(() => console.log("deleted"))
				.catch((err) => {
					console.log(err);
				});
		}

		for (let i = 0; i < ascending.length; i++) {
			try {
				await fetch(
					"https://ey6zu8r7jk.execute-api.eu-west-2.amazonaws.com/prod/addmultiple",
					{
						body: JSON.stringify(body),
						method: "POST",
					}
				);
			} catch (error) {
				console.log(error);
			}
		}
	};

	await checkAscending();
	if (!isAscending) {
		await sortDbThreshold();
	}

	return true;
};
