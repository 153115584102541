import React from "react";
import styled from "@emotion/styled";
import Loader from "react-loader-spinner";

const SpinnerMain = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 75%;
	margin-top: 10em;
	margin-bottom: auto;
`;

const LoadingSpinner: React.FC = (): JSX.Element => {
	return (
		<SpinnerMain>
			<SpinnerContainer>
				<Loader
					type="TailSpin"
					color="#962127"
					height={100}
					width={100}
					timeout={15000}
				/>
			</SpinnerContainer>
		</SpinnerMain>
	);
};

export default LoadingSpinner;
