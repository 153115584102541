import { createContext } from "react";

interface UserContextProps {
	user: object | undefined;
	setUser?: React.Dispatch<React.SetStateAction<object | undefined>>;
}

export const UserContext = createContext<UserContextProps>({
	user: undefined,
});
