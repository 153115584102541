import React, { useState, useEffect } from "react";
import { getDiscounts } from "../../serviceWorker";
//import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import axios from "axios";
import ConfirmationPopUp from "../ConfirmationPopUp/ConfirmationPopUp";
import { useSortDbTier } from "../../hooks/useSortDbTier";
import { sortDB } from "../../utils/sortDb";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {
	RemoveTierContainer,
	RemoveTierAlignment,
	RemoveTierContent,
	ButtonContainer,
} from "./styles";
interface discountOptions {
	tier: number;
	extendedAdditional: number;
	basePrice: number;
	threshold: number;
}
interface RemoveTierProps {
	clearOptions: () => void;
}

const RemoveTier: React.FC<RemoveTierProps> = ({
	clearOptions,
}): JSX.Element => {
	const [discounts, setdiscounts] = useState<Array<discountOptions>>([]);
	const [selectedTier, setselectedTier] = useState<Array<discountOptions>>(
		[]
	);
	const [sorted, setSorted] = useState<Array<discountOptions>>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
	const [disableSubmit, setdisableSubmit] = useState<boolean>(true);

	useEffect(() => {
		sortDB().then((response) => {
			setLoading(response);
		});
		getDiscounts().then((response) => {
			setdiscounts(response);
		});
	}, []);
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		getDiscounts().then((response) => {
			setdiscounts(response);
		});
		setselectedTier([]);
		const dropdown = document.getElementById(
			"dropdown"
		) as HTMLSelectElement;
		dropdown.selectedIndex = 0;
	};
	useEffect(() => {
		getDiscounts().then((response) => {
			setdiscounts(response);
		});
	}, [loading]);

	useEffect(() => {
		if (discounts[0]) {
			setSorted(
				discounts.sort((a: discountOptions, b: discountOptions) => {
					return a.threshold > b.threshold ? 1 : -1;
				})
			);
		}
	}, [discounts]);

	useEffect(() => {
		if (selectedTier[0]) {
			setdisableSubmit(false);
		} else {
			setdisableSubmit(true);
		}
	}, [selectedTier]);

	async function removeTier() {
		handleClickOpen();
		await axios.delete(
			`https://ey6zu8r7jk.execute-api.eu-west-2.amazonaws.com/prod/delete?tier=${selectedTier[0].tier}`
		);
		sortDB();
		setLoadingRequest(false);
	}
	useSortDbTier();
	function numberWithCommas(x: number) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	return !loading ? (
		<RemoveTierContainer>
			<RemoveTierAlignment>
				<RemoveTierContent>
					<ConfirmationPopUp
						open={open}
						handleClose={handleClose}
						handleClickOpen={handleClickOpen}
						type="Removed"
						loading={loadingRequest}
					/>
					<h1>Remove Tier</h1>
					<select
						id="dropdown"
						onChange={(e: any) => {
							if (!e) return;
							const index = e.nativeEvent.target.selectedIndex;
							const label = e.nativeEvent.target[index].text;
							const tier = parseInt(
								label.substr(label.length - 1)
							);

							setselectedTier(
								sorted.filter((option) => {
									return option.tier === tier;
								})
							);
						}}
					>
						<option></option>
						{discounts &&
							discounts.map((option: discountOptions) => {
								return (
									<option
										key={option.tier}
									>{`Tier ${option.tier}`}</option>
								);
							})}
					</select>
					<h4>Threshold (GB)</h4>
					<input
						id="threshold"
						readOnly
						style={{
							textAlign: "left",
							fontSize: 15,
							height: "3.5vh",
							marginBottom: 20,
						}}
						value={
							selectedTier[0] !== undefined
								? numberWithCommas(selectedTier[0].threshold)
								: undefined || ""
						}
					></input>

					<h4>Standard Retention Charge (£/GB/month)</h4>
					<input
						id="base-price"
						readOnly
						style={{
							textAlign: "left",
							fontSize: 15,
							height: "3.5vh",
							marginBottom: 20,
						}}
						value={
							selectedTier[0] !== undefined
								? selectedTier[0].basePrice
								: undefined || ""
						}
					></input>

					<h4>
						Additional Charge for Extended Retention (£/GB/month)
					</h4>
					<input
						id="extended-price"
						readOnly
						style={{
							textAlign: "left",
							fontSize: 15,
							height: "3.5vh",
							marginBottom: 20,
						}}
						value={
							selectedTier[0] !== undefined
								? selectedTier[0].extendedAdditional
								: undefined || ""
						}
					></input>

					<ButtonContainer>
						<Button
							label="Back"
							className="p-button-raised"
							style={{
								marginTop: 50,
								width: 100,
								fontFamily: "FujitsuSans",
							}}
							onClick={clearOptions}
						></Button>
						<Button
							id="remove-tier-button"
							label="Remove"
							className="p-button-raised"
							style={{
								marginTop: 50,
								width: 100,
								fontFamily: "FujitsuSans",
							}}
							disabled={disableSubmit}
							onClick={removeTier}
						></Button>
					</ButtonContainer>
				</RemoveTierContent>
			</RemoveTierAlignment>
		</RemoveTierContainer>
	) : (
		<LoadingSpinner />
	);
};

export default RemoveTier;
