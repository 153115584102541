import styled from "@emotion/styled";

export const DataMain = styled.div`
	border-radius: 15px;
	background-color: #ffe5dd;
	margin: 0.5em;
	height: 27rem;
	grid-column: 3/4;
	grid-row: 1/2;
	-webkit-box-shadow: 1px 2px 3px 4px #ccc;
	-moz-box-shadow: 1px 2px 3px 4px #ccc;
	box-shadow: 1px 2px 3px 4px #ccc;
`;

export const H2 = styled.h2`
	background-color: #962127;
	border-top-left-radius: 13px;
	border-top-right-radius: 13px;
	padding: 0.3em;
	margin-top: 0;
	color: #ffffff;
`;

export const CostGroup = styled.div`
	margin-left: 1em;
	margin-right: 1em;
	margin-top: 0.5em;
	border-bottom: 1px solid black;
`;

export const StyledH3 = styled.h3`
	font-size: 1.2em;
	margin: 0;
`;

export const Cost = styled.h4`
	font-size: 1.4em;
	margin: 0.5em;
	color: #5c7029;
`;
